import styled from '@mui/styled-engine';
import PropTypes from 'prop-types';

const Row = styled("div")(() => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
}));

Row.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Row;