import { Box, Container, Typography } from "@mui/material";
import moment from 'moment';
import "moment/locale/nl";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from 'react-moment';
import { Loader } from "../../components/Loader/Loader";
import { getSelectedLanguage } from "../../hooks/useLanguage";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectedFamilySelector } from "../Families/familySlice";
import { Patient } from "./Patient";
import { fetchPatients, patientsIsLoadingSelector, patientsSelector } from "./patientsSlice";


const PatientDetail: FunctionComponent<{
  patient: Patient;
}> = ({ patient }) => {
  const { t } = useTranslation();
  const selectedLanguage = getSelectedLanguage();

  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  const formatAge = (birthday: Date) => {
    const date = moment(birthday);
    const now = moment(new Date());
    const duration = moment.duration(now.diff(date));

    const days = duration.days();
    const months = duration.months();
    const years = duration.years();

    let yearsText = t("year");
    if (years > 1) yearsText = t("years");

    let monthsText = t("months");
    if (months === 1) monthsText = t("month");

    let daysText = t("days");
    if (days === 1) daysText = t("day");

    let result = '';
    if (years > 0) {
      result += `${years} ${yearsText}, `;
    }

    result += `${months} ${monthsText} ${t("and")} ${days} ${daysText}`;

    return result;
  };

  const age = formatAge(new Date(patient.date_of_birth));

  return (
    <Box key={patient.id} mb={3}>
      <Typography variant="h5" gutterBottom>
        {patient.first_name}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {`${t("date_of_birth")}: `} <Moment date={new Date(patient.date_of_birth)} format="dddd DD MMMM YYYY" locale={selectedLanguage} />
      </Typography>
      <Typography variant="body2" gutterBottom>
        {`${t("age")}: ${age}`}
      </Typography>
    </Box>
  );
}

export const Patients = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("Patients"));
  const dispatch = useAppDispatch();
  const patients = useAppSelector(patientsSelector);
  const isLoading = useAppSelector(patientsIsLoadingSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchPatients(selectedFamily.community_id));
    }
  }, [dispatch, selectedFamily]);

  return (
    <Container>
      <Loader isLoading={isLoading} />
      {patients.map((patient: Patient) => (
        <PatientDetail key={patient.id} patient={patient} />
      ))}
    </Container>
  );
};
