import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { VideoPlaybackResult } from "./VideoPlaybackResult";

interface VideoState {
  isLoading: boolean;
  errorVideo: string | undefined;
  reportedPlaybacks: VideoPlaybackResult[],
}

const initialState: VideoState = {
  isLoading: false,
  errorVideo: undefined,
  reportedPlaybacks: [],
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<VideoState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorVideo(
      state,
      action: PayloadAction<VideoState["errorVideo"]>
    ) {
      state.errorVideo = action.payload;
    },
    setReported(
      state,
      action: PayloadAction<VideoState["reportedPlaybacks"]>
    ) {
      state.reportedPlaybacks = action.payload;
    },
  },
});

export const videoReducer = videoSlice.reducer;

const {
  setErrorVideo,
  setReported,
} = videoSlice.actions;

export const saveVideoResult = (videoPlaybackResult: VideoPlaybackResult): AppThunk => async (
  dispatch,
  getState,
) => {
  batch(() => {
    dispatch(setErrorVideo(undefined));
  });
  try {
    await httpClient.post(`/video/videoresult`, videoPlaybackResult);
    const reportedPlaybacks = getState()?.video.reportedPlaybacks;
    dispatch(setReported(reportedPlaybacks.concat(videoPlaybackResult as VideoPlaybackResult)));
  } catch (err) {
    console.error(err);
  }
};


export const reportedPlaybacksSelector = (state: RootState) =>
  state.video.reportedPlaybacks;
export const videoIsLoadingSelector = (state: RootState) =>
  state.video.isLoading;
export const videoErrorVideoSelector = (state: RootState) =>
  state.video.errorVideo;
