import { Box, Divider, Grid, Typography as UnstyledTypography } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { User } from "../Auth/Auth";
import { authUserSelector } from "../Auth/authSlice";
import { Family } from "../Families/Family";
import { selectedFamilySelector } from "../Families/familySlice";
import { fetchMembers } from "../Members/membersSlice";
import { Message } from "./Chat";
import ChatWriter from "./Components/ChatWriter";
import MessageCard from "./Components/MessageCard";
import { chatMessagesSelector, fetchChatMessages } from "./chatSlice";

// const ContainerWithPadding = styled(Container)(({ theme }: any) => ({
//   padding: theme.spacing(0, 1, 8),
// }));

const ContainerGrid = styled(Grid)(() => ({
  height: isMobile ? "calc(100% - 8px)" : "100%",
  paddingLeft: isMobile ? "8px" : "108px",
  paddingRight: "8px",
  paddingBottom: "8px",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

const TitleGrid = styled(Grid)(() => ({
  maxHeight: "50px",
}));

const Typography = styled(UnstyledTypography)(() => ({
  textTransform: "uppercase",
}));

const ChatGrid = styled(Grid)(() => ({
  paddingBottom: "60px",
}));


// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     padding: theme.spacing(0, 1, 8),
//   },
// }));

export const Chats = () => {
  const { t } = useTranslation();
  // const classes = useStyles();
  const dispatch = useAppDispatch();
  useTitle(t("BabyWatch") + " - " + t("chat"));
  const user = useAppSelector(authUserSelector);
  const messages = useAppSelector(chatMessagesSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchMembers(selectedFamily.community_id));
      dispatch(fetchChatMessages(selectedFamily.community_id));
    }
  }, [selectedFamily, dispatch]);

  return (
    <ContainerGrid container>
      <TitleGrid item xs={12}>
        <Typography variant="h3" color="primary" gutterBottom>
          {t("chat")}
        </Typography>
      </TitleGrid>
      <ChatGrid item xs={12}>
        <ChatWriter family={selectedFamily as Family} user={user as User} />
        <Divider />
        <Box mt={2}>
          {messages.map((message: Message, index) => (
            <Box mb={2} key={index}>
              <MessageCard message={message} key={index} showFavorites={true} />
            </Box>
          ))}
        </Box>
      </ChatGrid>
    </ContainerGrid>
  );
};
