import { ArrowForward, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, IconButton as UnstyledIconButton } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const IconButton = styled(UnstyledIconButton)(() => ({
  padding: isMobile ? "6px" : "12px",
}));


// const useStyles = makeStyles(() => ({
//   button: {
//     padding: isMobile ? "6px" : "12px"
//   }
// }));

interface VideoControlsPropsInferface {
  nextAction?: () => void;
  fullScreenAction?: () => void;
  exitFullScreenAction?: () => void;
  allowPaging: boolean;
  isFullScreen: boolean;
  className?: string;
}

export const VideoControls = (props: VideoControlsPropsInferface) => {

  const { t } = useTranslation();
  // const classes = useStyles();

  return (
    <Box className={props.className}>
      {
        props.allowPaging &&
        <IconButton
          onClick={() => props.nextAction ? props.nextAction() : null}
          aria-label={t("next")}
          size="large">
          <ArrowForward fontSize={"large"} />
        </IconButton>

      }
      {
        props.isFullScreen &&
        <IconButton
          onClick={() => props.exitFullScreenAction ? props.exitFullScreenAction() : null}
          aria-label={t("exitfullscreen")}
          size="large">
          <FullscreenExit fontSize={"large"} />
        </IconButton>
      }
      {
        !props.isFullScreen &&
        <IconButton
          onClick={() => props.fullScreenAction ? props.fullScreenAction() : null}
          aria-label={t("fullscreen")}
          size="large">
          <Fullscreen fontSize={"large"} />
        </IconButton>
      }
    </Box>
  );
};


