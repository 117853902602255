import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User as OidcUser } from "oidc-client";
import { batch } from "react-redux";
import { toast } from 'react-toastify';
import { setSelectedLanguage } from "../../hooks/useLanguage";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { loadPersistedState, persistState } from "../../utils/localStorage";
import ls from "../../utils/localStorageKey";
import userManager from "../../utils/userManager";
import { deactivateRefreshTimer } from "../Patients/patientsSlice";
import { User } from "./Auth";

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  oidcUser?: OidcUser;
  user?: User;
  stayLoggedIn: boolean;
  isInActiveFamily: boolean;

}

const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  oidcUser: undefined,
  user: undefined,
  stayLoggedIn: true,
  isInActiveFamily: true,
  ...loadPersistedState(ls("auth")),
};

if (initialState.oidcUser) {
  httpClient.defaults.headers.common['Authorization'] = `Bearer ${initialState.oidcUser.access_token}`;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setAuthUser(state, action: PayloadAction<OidcUser>) {
      state.oidcUser = action.payload;
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${state.oidcUser.access_token}`;

      state.isAuthenticated = true;
      persistState(ls("auth"), {
        isAuthenticated: state.isAuthenticated,
        stayLoggedIn: state.stayLoggedIn,
        identityId: state.oidcUser.profile.sub
      });
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      setSelectedLanguage(action.payload.language === "en" ? "en" : "nl");
    },
    setAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    signOut(state) {
      delete state.oidcUser;
      delete httpClient.defaults.headers.common['Authorization'];
      state.isAuthenticated = false;
      deactivateRefreshTimer();
      persistState(ls("auth"), {
        isAuthenticated: state.isAuthenticated,
        stayLoggedIn: state.stayLoggedIn,
        identityId: null
      });
    },
    setStayLoggedIn(state, action: PayloadAction<AuthState["stayLoggedIn"]>) {
      state.stayLoggedIn = action.payload;
      persistState(ls("auth"), {
        isAuthenticated: state.isAuthenticated,
        user: state.oidcUser,
        stayLoggedIn: state.stayLoggedIn,
      });
    },
  },
});

export const authReducer = authSlice.reducer;

const { setAuthenticated, signOut, setStayLoggedIn, setAuthUser, setUser, setLoading } = authSlice.actions;
export { setStayLoggedIn, signOut };

export const setLoggedInUser = (user: OidcUser): AppThunk => async (dispatch: any) =>
  new Promise<OidcUser>(function (resolve, reject) {
    dispatch(setLoading(true));
    try {
      batch(() => {
        dispatch(setAuthUser(user));
        dispatch(fetchUser());
        dispatch(setLoading(false));
      });
      resolve(user);
    } catch (err) {
      toast.error("Gebruiker kon niet worden gezet: " + err);
      reject();
    }
    finally {
      dispatch(setLoading(false));
    }
  });


export const signIn = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  userManager.signinRedirect()
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      toast.error(error.response?.data.message || error.message);
    });
}

export const fetchUser = (): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
  });

  httpClient.get<User>(`user/current`)
    .then((response) => {
      batch(() => {
        dispatch(setAuthenticated(true));
        dispatch(setUser(response.data));
        dispatch(setLoading(false));
      });
    })
    .catch(() => {
      batch(() => {
        dispatch(setAuthenticated(false));
        dispatch(setLoading(false));
      });
    });
};

export const authVerify = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  userManager.getUser()
    .then((user) => {

      if (user === null || user === undefined) {
        batch(() => {
          dispatch(signOut());
          dispatch(setLoading(false));
        });
      } else {
        batch(() => {
          dispatch(setAuthUser(user));
          dispatch(fetchUser());
          dispatch(setLoading(false));
        });
      }
    })
    .catch((error) => {
      batch(() => {
        dispatch(signOut());
        dispatch(setLoading(false));
      });
      toast.error(error.response?.data.message || error.message);
    })
};

export const authIsAuthenticatedSelector = (state: RootState) => state.auth.isAuthenticated;
export const authUserSelector = (state: RootState) => state.auth.user;
export const authIsLoadingSelector = (state: RootState) => state.auth.isLoading;
export const authStayLoggedInSelector = (state: RootState) => state.auth.stayLoggedIn;
export const authTokenSelector = (state: RootState) => state.auth.oidcUser?.access_token;
export const authIsCustomerSelector = (state: RootState) => state.auth.user?.role_name === 'BabywatchCustomer';
export const authIsAdministratorSelector = (state: RootState) => state.auth.user?.role_name === 'BabywatchAdministrator';
export const authIsActiveFamilySelector = (state: RootState) => state.auth.user?.family_id;
