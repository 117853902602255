const en = {
  translation: {
    // Defaults
    "Babywatch": "Babywatch",
    "BabyWatch": "BabyWatch",
    "no_account": "No account",
    "Network Error": "Network Error",
    "No": "No",
    "Yes": "Yes",
    "more": "More",
    "Loading": "Loading data...",

    //Tables
    "noMatch": "Sorry, no matching records found",
    "sort": "Sort",
    "actions": "Actions",

    // Components
    // LanguageSelect
    "Dutch": "Dutch",
    "English": "English",

    // MemberModal
    "Update user": "Update user",
    "delete": "delete",
    "save": "save",
    "emailExists": "Email address is already in use",
    "memberRemoved": "Member has been removed from the community",
    "memberUpdated": "Member has been updated",
    "memberAdded": "Member has been added to the community",
    "this field is required": "This field is required",
    "email is not valid": "Email is not valid",
    "Delete member": "Delete user",

    // UserMenu
    "messages": "Messages",
    "settings": "Settings",
    "selectlanguage": "Select language",
    "signout": "Sign out",

    // Login Page
    "login": "Sign in",
    "login_message": "Click to log in",
    "Login": "Login",
    "more_info": "Click on the link for more information about Babywatch. ",
    "more_info_btn": "more information",

    // Chat
    "chat": "Family chat",
    "invalid file type": "invalid file type",
    "type message": "Type message",
    "post": "Post",
    "chatAddFailed": "Message could not be saved",
    "chatFavoriteAddFailed": "Like could not be added",
    "chatFavoriteDeleteFailed": "Like could not be removed",

    // Message Card
    "you liked this post": "You have liked this post",
    "you and n otherd likes this post": "You and {{n}} others have liked this post",

    // Home Page
    "year": "year",
    "years": "years",
    "month": "month",
    "months": "months",
    "day": "day",
    "days": "days",
    "and": "and",
    "loggedin": "You are logged in",
    "welcome": "Welcome",
    "parents": "Parents",
    "babies": "Babies",
    "date_of_birth": "Date of birth",
    "unauthorized": "Unauthorized",
    "unauthorized message": "You cannot access this page.",
    "cancel": "Cancel",

    //Families
    "family": "Family",
    "families": "Families",
    "selectfamily": "Click on one of the communities below to continue. ",

    //Devices
    "devices": "Devices",
    "memberdevices": "Device usage",
    "desktops": "Desktops",
    "tablets": "Tablets",
    "mobiles": "Mobiles",
    "last login": "Last login",
    "video success": "Video success",
    "operatingsystem": "Platform",
    "browser": "Browser",
    "browser_version": "Browser version",
    "device_type": "Device type",
    "last_playback_success": "Last succesfull play",
    "last_playback_failed": "Last failed play",
    "playcount": "Play count",

    // Members
    "members": "Members",
    "friends": "Friends",
    "add friend": "Add family member or friend",
    "add parent": "Add parent",
    "add babywatchcustomer": "Add customer",
    "add babywatchadministrator": "Add administrator",
    "view friend": "Friend details",
    "view parent": "Parent details",
    "view babywatchcustomer": "Customer details",
    "view babywatchadministrator": "Administrator details",
    "add friend info": "Invite your friends to share the video of your baby.\nThey will receive an invite mail with instructions. Friends cannot see the messages of the hospital and they cannot modify the family.",
    "add parent info": "Invite your partner to share the video of your baby.\nAn invite mail will be sent with instructions. All parents are able to add and remove members to and from the family.",
    "view friend info": "Friends can see the videostream of the baby and are able to chat with the other family members.",
    "view parent info": "Parents can change the members of the family and are able to receive messages from the family.",
    "view babywatchcustomer info": "",
    "view babywatchadministrator info": "",
    "userDeleteFailed": "User could not be removed",
    "BabywatchAdministrator": "Administrator",
    "BabywatchCustomer": "Customer",
    "administrators": "Administrators",

    // Messages
    "user": "User",
    "message": "Message",
    "messageinfo": "Here, you will find the information the hospital wishes to share with you. This information is only accessible to you as a parent. ",
    "nomessages": "No messages have been received from the hospital yet. ",

    // Not Found
    "Page not found": "Page not found!",
    "Back to homepage": "Back to homepage",

    // Patients
    "age": "Age",
    "Patients": "Babies",

    // Settings
    "account": "Account",
    "email address": "Email address",
    "phonenumber": "Phone number",
    "appsettings": "App settings",
    "add": "Add",
    "Add user": "Add user",
    "firstname": "First name",
    "lastname": "Last name",
    "name": "Name",
    "email": "Email",
    "Parent": "Parent",
    "Family": "Family",
    "Friend": "Friend",
    "role": "Role",
    "continue": "continue",
    "upload": "upload",
    "Profile image": "Profile image",
    "userSaveFailed": "User could not be saved",
    "smsVerificationTitle": "SMS verification",
    "smsVerificationText": "A text message has been sent to your phone for verification",
    "Invalid code": "Invalid code",

    // Video
    "video": "Video",
    "baby": "Baby",
    "Treatment in progress": "Treatment in progress",
    "Patient is discharged": "Patient is discharged",
    // Video Player
    "exitfullscreen": "Exit fullscreen",
    "fullscreen": "Fullscreen",
    "next": "Next",

    // Deactivated
    "Community deactivated": "Community deactivated",
    "Not Active Message": "The Community is deactivated, Click on Back to Login",
    "Download Message": "The Community is deactivated, click on 'Download PDF' to download a file with al your memories",
    "Download PDF": "Download PDF",
    "Back to login": "Back to Login",
  },
};

export default en;
