import { Box, Button, Typography, Container as UnstyledContainer } from "@mui/material";
import styled from '@mui/styled-engine';
import React from "react";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import styles from "../../../styles";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch } from "../../store/hooks";
import logo from "./assets/logo.png";
import { signIn } from "./authSlice";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const Logo = styled("img")(({ theme }: any) => ({
  maxWidth: "70%",
  maxHeight: "60%",
  paddingBottom: theme.spacing(6),
}));

const LoginMessage = styled(Typography)(({ theme }: any) => ({
  fontWeight: 600,
}));

const LoginButton = styled(Button)(({ theme }: any) => ({
  ...styles.pinkBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
}));

const MoreInfoBox = styled(Box)(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginTop: theme.spacing(8),
}));

const MoreInfoButton = styled(Button)(({ theme }: any) => ({
  ...styles.purpleBackground,
  padding: theme.spacing(1, 3),
  fontSize: "0.875rem",
  borderRadius: "2rem",
  lineHeight: "1rem",
}));

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     height: "100vh",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     // padding: theme.spacing(3, 2, 0)
//   },
//   login: {
//     backgroundColor: theme.palette.background.paper,
//     paddingTop: theme.spacing(8),
//     textAlign: "center",
//   },
//   title: {
//     color: theme.palette.primary.contrastText,
//   },
//   block: {
//     position: "relative",
//     padding: theme.spacing(0, 2),
//     top: theme.spacing(6),
//   },
//   blockPaper: {
//     padding: theme.spacing(4, 2),
//     textAlign: "center",
//     background: theme.palette.primary.main,
//     top: -theme.spacing(1),
//   },
//   loginBlock: {
//     padding: theme.spacing(0, 0, 0),
//   },
//   logo: {
//     maxWidth: "70%",
//     paddingBottom: theme.spacing(6),
//   },
//   loginBtn: {
//     ...styles.pinkBackground,
//     padding: theme.spacing(1, 3),
//     fontSize: "1.125rem",
//     borderRadius: "2rem",
//     lineHeight: "2rem",
//   },
//   loginMessage: {
//     fontWeight: 600,
//   },
//   moreInfo: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     textAlign: "center",
//     marginTop: theme.spacing(8),
//   },
//   moreInfoBtn: {
//     ...styles.purpleBackground,
//     padding: theme.spacing(1, 3),
//     fontSize: "0.875rem",
//     borderRadius: "2rem",
//     lineHeight: "1rem",
//   },
// }));

export const Login = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("Login"));
  // const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleFormSubmit = async () => {
    // if (!isLoading) {
    try {
      // await userManager.signinRedirect();
      await dispatch(signIn());
    } catch (err) {
      // const error: needleError = err;
      // if (error.response?.status === 401) {
      //   const message = (
      //     <Translation>
      //       {(t) => t("User not found or wrong password")}
      //     </Translation>
      //   );
      //   enqueueSnackbar(message, { variant: "error" });
      //   setFieldError("username", message as any);
      //   setFieldError("password", message as any);
      // }
    }
    // }
  };

  const redirectInfo = () => {
    window.open(config.infoUrl)
  };

  return (
    <Container>
      <Logo src={logo} alt="" />

      <LoginMessage variant="h5" color="primary" gutterBottom>
        {t("login_message")}
      </LoginMessage>
      <LoginButton id="loginButton" onClick={handleFormSubmit} color="primary">
        {t("login")}
      </LoginButton>

      <MoreInfoBox>
        <Typography variant="body1" color="primary" gutterBottom>
          {t("more_info")}
        </Typography>
        <MoreInfoButton onClick={redirectInfo} color="primary">
          {t("more_info_btn")}
        </MoreInfoButton>
      </MoreInfoBox>
    </Container>
  );
};
