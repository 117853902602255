import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Message } from "./Message";


interface MessagesState {
  isLoading: boolean;
  errorMessage: string | undefined;
  messages: Message[];
}

const initialState: MessagesState = {
  isLoading: false,
  errorMessage: undefined,
  messages: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<MessagesState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(
      state,
      action: PayloadAction<MessagesState["errorMessage"]>
    ) {
      state.errorMessage = action.payload;
    },
    setMessages(state, action: PayloadAction<MessagesState["messages"]>) {
      state.messages = action.payload;
    },
  },
});

export const messagesReducer = messagesSlice.reducer;

const {
  setMessages,
  setLoading,
  setErrorMessage,
} = messagesSlice.actions;


export const fetchMessages = (communityId: string): AppThunk => async (
  dispatch
) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  try {
    const route = `message/${communityId}`;
    const response = await httpClient.get<{ data: { messages: [] } }>(route);
    const messages = response.data.data.messages;
    batch(() => {
      dispatch(setMessages(messages));
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(setLoading(false));
      dispatch(
        setErrorMessage(
          "There was an error while trying to fetch the messages list"
        )
      );
    });
    console.error(err);
  }
};

export const messagesSelector = (state: RootState) => state.messages.messages;
export const messagesIsLoadingSelector = (state: RootState) =>
  state.messages.isLoading;
export const messagesErrorMessageSelector = (state: RootState) =>
  state.messages.errorMessage;
