import { KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { BottomNavigationActionTypeMap, Divider, Drawer, ExtendButtonBase, IconButton, ListItem, BottomNavigation as UnstyledBottomNavigation, BottomNavigationAction as UnstyledBottomNavigationAction, List as UnstyledList, ListItemIcon as UnstyledListItemIcon, ListItemText as UnstyledListItemText } from "@mui/material";
import { darken } from "@mui/material/styles";
import styled from '@mui/styled-engine';
import { DistributiveOmit } from '@mui/types';
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from "react-router-dom";
import { selectedFamilySelector } from '../../domain/Families/familySlice';
import { useAppSelector } from "../../store/hooks";
import { useAppHeaderSelector } from "../AppHeader/appHeaderSlice";
import { NavigationMenu } from "../NavigationMenu";
import "./TabNavigation.css";

const ActionButtonListItem = styled(ListItem)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  color: darken(theme.palette.primary.contrastText, 0.2),
}));

const CenteredActionButtonListItem = styled(ActionButtonListItem)(() => ({
  position: "relative",
  left: "45%",
}));

const ListItemIcon = styled(UnstyledListItemIcon)(({ theme }: any) => ({
  color: theme.palette.primary.contrastText,
}));

const ListItemText = styled(UnstyledListItemText)(({ theme }: any) => ({
  color: theme.palette.primary.contrastText,
}));

const BottomNavigation = styled(UnstyledBottomNavigation)(({ theme }: any) => ({
  width: "100%",
  position: "fixed",
  bottom: 0,
  zIndex: 5,
  background: theme.palette.primary.main,
}));

const List = styled(UnstyledList)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  color: darken(theme.palette.primary.contrastText, 0.2),
}));

const BottomNavigationAction = styled(UnstyledBottomNavigationAction)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  color: darken(theme.palette.primary.contrastText, 0.2),
}));

// const useStyles = makeStyles((theme: Theme) => ({
//   bottomNavigation: {
//     width: "100%",
//     position: "fixed",
//     bottom: 0,
//     zIndex: 5,
//     background: theme.palette.primary.main,
//   },
//   actionButton: {
//     background: theme.palette.primary.main,
//     color: darken(theme.palette.primary.contrastText, 0.2),
//   },
//   selectedActionButton: {
//     color: theme.palette.primary.contrastText,
//   },
//   actionButtonWrapper: {},
//   navigationIcon: {
//     width: theme.spacing(3),
//     height: theme.spacing(3),
//     filter: "invert(100%) saturate(0%) brightness(99%) contrast(255%)",
//   },
//   center: {
//     position: "relative",
//     left: "45%",
//   }
// }));

const UnstyledBottomNavigationMore: ExtendButtonBase<BottomNavigationActionTypeMap<unknown, "button">> = (props: any) => {
  return (
    <IconButton
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      size="large">
      {props.icon}
    </IconButton>
  );
}

export const BottomNavigationMore = styled(UnstyledBottomNavigationMore)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  color: darken(theme.palette.primary.contrastText, 0.2),
}));

export const TabNavigation: FunctionComponent<{
  navigationItems: NavigationMenu[];
}> = ({ navigationItems }) => {
  const { t } = useTranslation();
  // const classes = useStyles();
  const location = useLocation();
  const family = useAppSelector(selectedFamilySelector);
  const showAppHeader = useAppSelector(useAppHeaderSelector);

  const selectedNavigationItem = navigationItems.find((item) => item.url === location.pathname);
  let navigationIndex = 0;
  if (selectedNavigationItem !== undefined) {
    navigationIndex = navigationItems.indexOf(selectedNavigationItem);
  }
  const [value, setValue] = useState<any>(navigationIndex);
  const [drawerIsOpen, setDrawerOpen] = useState<boolean>(false);

  const changeValue = (event: any, newValue: any) => {
    setValue(newValue);
    setDrawerOpen(false);
  };

  function ListItemLink(props: NavigationMenu) {
    const { icon, title, url } = props;

    const renderLink = React.useMemo(
      () =>
        // eslint-disable-next-line react/display-name
        React.forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((itemProps, ref) => (
          <RouterLink to={url} ref={ref} {...itemProps} />
        )),
      [url],
    );

    return (
      <li>
        <ActionButtonListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={title} />
        </ActionButtonListItem>
      </li>
    );
  }


  return (
    showAppHeader ?
      <BottomNavigation value={value} onChange={changeValue} showLabels>
        {navigationItems.slice(0, 5).map((item: NavigationMenu, index) => {
          return (
            (navigationItems.length < 6 || (navigationItems.length > 5 && index < 4)) ?
              (
                <BottomNavigationAction
                  key={index}
                  component={Link}
                  to={item.url}
                  label={item.title}
                  icon={item.icon}
                  disabled={(!(family?.community_id))}
                />
              ) :
              (
                // eslint-disable-next-line 
                // <a key={index}>
                <BottomNavigationMore
                  key={index}
                  label={t('more')}
                  disabled={(!(family?.community_id))}
                  icon={<MoreHoriz />}
                  onClick={() => setDrawerOpen(true)} />
                // </a>
              )
          );
        })}
        <Drawer open={drawerIsOpen} onClose={() => setDrawerOpen(false)} onClick={() => changeValue(undefined, undefined)}
          onKeyDown={() => setDrawerOpen(false)} anchor="bottom" >
          <List>
            <CenteredActionButtonListItem>
              <ListItemIcon><KeyboardArrowDown /></ListItemIcon>
            </CenteredActionButtonListItem>
            <Divider />
            {navigationItems.slice(4, undefined).map((item: NavigationMenu, index) => {
              return (
                <ListItemLink url={item.url} icon={item.icon} key={item.title} title={item.title} adminOrder={item.adminOrder} />
              )
            })}
          </List>
        </Drawer>
      </BottomNavigation>
      : <> </>
  );
};
