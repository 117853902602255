

import { Button, Typography, Container as UnstyledContainer } from "@mui/material";
import styled from '@mui/styled-engine';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "../../../styles";
import useTitle from "../../hooks/useTitle";

const Container = styled(UnstyledContainer)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const BackButton = styled(Button)(({ theme }: any) => ({
  ...styles.pinkBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
}));

const Title = styled(Typography)(() => ({
  fontWeight: 600,
}));



export const Unauthorized = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("unauthorized"));
  const navigate = useNavigate();

  const goHome = async () => {
    navigate("/");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇 Redirects to about page, note the `replace: true`
      navigate('/', { replace: true });
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      <Title variant="h5" color="primary" gutterBottom>
        {t("unauthorized")}
      </Title>
      <br />
      <Typography variant="body2" color="primary" gutterBottom>
        {t("unauthorized message")}
      </Typography>
      <br />

      <BackButton onClick={goHome} color="primary"> {t("Back to homepage")} </BackButton>
    </Container>
  );
};
