const nl = {
  translation: {
    // Algemeen
    "Babywatch": "Babywatch",
    "BabyWatch": "BabyWatch",
    "no_account": "Nog geen account",
    "Network Error": "Netwerk Error",
    "No": "Nee",
    "Yes": "Ja",
    "more": "Meer",
    "Loading": "Gegevens worden opgehaald...",

    //Tables
    "noMatch": "Geen resultaten gevonden",
    "sort": "Sorteren",
    "actions": "Acties",

    // Components
    // LanguageSelect
    "Dutch": "Nederlands",
    "English": "Engels",

    // MemberModal
    "Update user": "Gebruiker bijwerken",
    "delete": "verwijderen",
    "save": "opslaan",
    "emailExists": "E-mailadres is al in gebruik",
    "memberRemoved": "Lid van de community is verwijderd",
    "memberUpdated": "Lid van de community is geüpdatet",
    "memberAdded": "Een nieuwe lid van de community is toegevoegd",
    "this field is required": "Dit veld is verplicht",
    "email is not valid": "E-mail is niet goed ingevuld",
    "Delete member": "Gebruiker verwijderen",

    // UserMenu
    "messages": "Berichten",
    "settings": "Instellingen",
    "selectlanguage": "Taalkeuze",
    "signout": "Afmelden",

    // Login Pagina
    "Login": "Aanmelden",
    "login_message": "Direct inloggen",
    "login": "Aanmelden",
    "more_info": "Klik op de link voor meer informatie over Babywatch. ",
    "more_info_btn": "meer informatie",

    // Chat
    "chat": "Familiechat",
    "invalid file type": "Bestandstype is niet toegestaan",
    "type message": "Typ bericht",
    "post": "Plaatsen",
    "chatAddFailed": "Bericht kon niet worden opgeslagen",
    "chatFavoriteAddFailed": "Favoriet kon niet worden toegevoegd",
    "chatFavoriteDeleteFailed": "Favoriet kon niet worden verwijderd",

    // Message card
    "you liked this post": "Jij vind dit bericht leuk",
    "you and n others liked this post": "Jij en {{n}} andere vinden dit bericht leuk",

    // Home pagina
    "year": "jaar",
    "years": "jaar",
    "month": "maand",
    "months": "maanden",
    "day": "dag",
    "days": "dagen",
    "and": "en",
    "loggedin": "Je bent ingelogd",
    "welcome": "Welkom",
    "parents": "Ouders",
    "babies": "Baby's",
    "date_of_birth": "Geboortedatum",
    "unauthorized": "Geen toegang",
    "unauthorized message": "Je kunt deze pagina niet openen.",
    "cancel": "Annuleren",

    //Families
    "family": "Familie",
    "families": "Families",
    "selectfamily": "Klik op één van de communities hieronder om verder te gaan. ",

    //Devices
    "devices": "Apparaten",
    "memberdevices": "Apparaatgebruik",
    "desktops": "Desktops",
    "tablets": "Tablets",
    "mobiles": "Mobiel",
    "last login": "Aangemeld",
    "video success": "Succes",
    "operatingsystem": "Platform",
    "browser": "Browser",
    "browser_version": "Browser versie",
    "device_type": "Type",
    "last_playback_success": "Laatst afgespeeld",
    "last_playback_failed": "Gefaald",
    "playcount": "Afgespeeld",

    // Leden
    "members": "Leden",
    "friends": "Vrienden",
    "add friend": "Familielid, vriend of vriendin toevoegen",
    "add parent": "Ouder toevoegen",
    "add babywatchcustomer": "Klant toevoegen",
    "add babywatchadministrator": "Beheerder toevoegen",
    "view friend": "Vriend details",
    "view parent": "Ouder details",
    "view babywatchcustomer": "Klant details",
    "view babywatchadministrator": "Beheerder details",
    "add friend info": "Nodig uw vrienden uit zodat u de beelden van uw kind kunt delen met hen.\nZe ontvangen een uitnodigingsmail met instructies. Uw vrienden kunnen de prive berichten van de verpleegkundige niet zien, en ze kunnen de familie niet aanpassen.",
    "add parent info": "Nodig uw partner uit zodat u alles met hem/haar kunt delen.\nEr wordt een uitnodigingsmail verstuurd met instructies. Ouders zijn de beheerders van de familie en kunnen ook andere leden toevoegen en verwijderen.",
    "view friend info": "Vrienden kunnen de videostream van de baby zien en kunnen chatten met de rest van de familie.",
    "view parent info": "Ouders zijn de beheerders van de familie. Ze kunnen leden toevoegen en uitnodigen en kunnen berichten van het ziekenhuis ontvangen.",
    "view babywatchcustomer info": "",
    "view babywatchadministrator info": "",
    "userDeleteFailed": "Gebruiker kon niet worden verwijderd.",
    "BabywatchAdministrator": "Beheerder",
    "BabywatchCustomer": "Klant",
    "administrators": "Beheerders",

    // Berichten
    "user": "Gebruiker",
    "message": "Bericht",
    "messageinfo": "Als het ziekenhuis informatie met u wil delen, zal dat hier zichtbaar worden. Deze informatie is alleen voor u als ouder beschikbaar. ",
    "nomessages": "Er zijn momenteel nog geen berichten ontvangen van het ziekenhuis.",

    // Not Found
    "Page not found": "Pagina niet gevonden!",
    "Back to homepage": "Terug naar hoofdpagina",

    // Patienten
    "age": "Leeftijd",
    "Patients": "Baby's",

    // Instellingen
    "account": "Profiel",
    "email address": "E-mailadres",
    "phonenumber": "Telefoonnummer",
    "appsettings": "Applicatie",
    "add": "Toevoegen",
    "Add user": "Gebruiker toevoegen",
    "firstname": "Voornaam",
    "lastname": "Achternaam",
    "name": "Naam",
    "email": "E-mail",
    "Parent": "Ouder",
    "Family": "Familie",
    "Friend": "Vriend",
    "role": "Gebruikersrol",
    "continue": "verder",
    "upload": "kies foto",
    "Profile image": "Profielfoto",
    "userSaveFailed": "Gebruiker kon niet worden opgeslagen",
    "smsVerificationTitle": "Sms verificatie",
    "smsVerificationText": "Vul de verificatiecode in die naar je mobiele telefoon is verstuurd",

    "Invalid code": "Ongeldige code",

    // Video
    "video": "Video",
    "baby": "Baby",
    "Treatment in progress": "Behandeling bezig",
    "Patient is discharged": "Baby is ontslagen",

    //VideoPlayer
    "exitfullscreen": "Volledig scherm sluiten",
    "fullscreen": "Volledig scherm",
    "next": "Volgende",

    // Deactivated
    "Community deactivated": "Community gedeactiveerd",
    "Not Active Message": "Je Community is gedeactiveerd, klik op Terug naar Login",
    "Download Message": "Je Community is gedeactiveerd, klik op 'download PDF' om een bestand van alle herinneringen te downloaden",
    "Download PDF": "Download PDF",
    "Back to login": "Terug naar Login",
  },
};

export default nl;
