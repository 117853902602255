import { Grid, Typography as UnstyledTypography } from "@mui/material";
import styled from '@mui/styled-engine';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams, DataGrid as UnstyledDataGrid } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/nl";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { GridWithTitle } from "../../../components/Layout/GridWithTitle";
import { getSelectedLanguage } from "../../../hooks/useLanguage";
import useTitle from "../../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectedFamilySelector } from "../../Families/familySlice";
import { fetchMemberDevices, memberDevicesSelector } from "../membersSlice";
import { MemberDeviceDetail } from "./MemberDeviceDetail";

const DataGrid = styled(UnstyledDataGrid)(({ theme }: any) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Typography = styled(UnstyledTypography)(() => ({
  textTransform: "uppercase",
}));

const TitleGrid = styled(Grid)(() => ({
  maxHeight: "50px",
}));



export const MemberDeviceTable = () => {
  const { t } = useTranslation();
  const dateValueFormatter = (params: GridValueFormatterParams<string>) => {
    return params.value === null ? '' : moment(params.value).format('DD-MM-YY HH:mm')
  }

  const columns: GridColDef[] = [
    {
      headerName: t("name"),
      field: "first_name",
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams<MemberDeviceDetail>) => {
        return `${params.row.first_name} ${params.row.last_name}`
      }
    },
    {
      headerName: t("operatingsystem"),
      field: "operating_system",
      flex: 1.5,
    },
    {
      headerName: t("browser"),
      field: "browser",
      flex: 1.5,
    },
    {
      headerName: t("browser_version"),
      field: "browser_version",
      flex: 1,
    },
    {
      headerName: t("device_type"),
      field: "device_type",
      flex: 1,
    },
    {
      headerName: t("last login"),
      field: "last_login",
      flex: 1,
      valueFormatter: dateValueFormatter,
    },
    {
      headerName: t("playcount"),
      field: "play_count",
      flex: 1,
    },
    {
      headerName: t("last_playback_success"),
      field: "last_playback_success",
      flex: 1,
      valueFormatter: dateValueFormatter,
    },
    {
      headerName: t("last_playback_failed"),
      field: "last_playback_failed",
      flex: 1,
      valueFormatter: dateValueFormatter,
    },
    {
      headerName: t("video success"),
      field: "video_playback_success_percentage",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `${params.value === -1 ? '' : params.value + ' %'}`;
      }
    },
  ];
  const columnsMobile = columns.filter(x => x.field === 'first_name' || x.field === 'operating_system' || x.field === 'browser' || x.field === 'video_playback_success_percentage');

  useTitle(t("Babywatch"));
  const dispatch = useAppDispatch();
  const selectedLanguage = getSelectedLanguage();
  const memberDevices = useAppSelector(memberDevicesSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);

  function GetNoRowsComponent() {
    return (
      <div>{t("noMatch")}</div>
    );
  }

  useEffect(() => {
    moment.locale(selectedLanguage);

    if (selectedFamily?.community_id) {
      dispatch(fetchMemberDevices(selectedFamily.community_id));
    }
  }, [dispatch, selectedLanguage, selectedFamily]);


  return (
    <GridWithTitle title={t("memberdevices") + `${selectedFamily === undefined ? '' : ' ' + t("family") + ' ' + selectedFamily.family_name}`}>
      <DataGrid
        rows={memberDevices}
        columns={isMobile ? columnsMobile : columns}
        disableColumnFilter={isMobile}
        initialState={{
          sorting: {
            sortModel: [{ field: 'first_name', sort: 'asc' }],
          },
        }}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 25]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: GetNoRowsComponent,
        }}
      />
    </GridWithTitle>
  );
};
