import { useEffect } from "react";
import { createGlobalState } from "react-use";

const useGlobalState = createGlobalState<string>("");

export default function useTitle(newTitle?: string): string {
  const [title, setTitle] = useGlobalState();

  useEffect(() => {
    if (newTitle !== undefined && title !== newTitle) {
      setTitle(newTitle);
    }
  }, [newTitle]);

  useEffect(() => {
    if (title) {
      window.document.title = title;
    }
  }, [title]);

  return title!;
}
