import { PersonAdd } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import "moment/locale/nl";
import React from "react";
import { useTranslation } from "react-i18next";
import { setOpenMember } from "../../domain/Members/membersSlice";
import { useAppDispatch } from "../../store/hooks";

export const AddButton = ({ role }: any) => {
  const { t } = useTranslation();
  const title = t("add " + (role || "friend"));
  const dispatch = useAppDispatch();

  const toggleModal = () => {
    dispatch(setOpenMember({ first_name: "", last_name: "", email: "", role_name: role }));
  };

  return (
    <Tooltip title={title}>
      <IconButton aria-label={title} onClick={toggleModal} size="large">
        <PersonAdd color="primary" />
      </IconButton>
    </Tooltip>
  );
};
