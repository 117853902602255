import { faRedo as Rotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import "./RotateIcon.scss";

const RotateIcon = ({ disabled, ...props }) => {
  const iconStyles = {
      cursor: "pointer",
      marginLeft: "10px",
      pointerEvents: disabled ? "none" : "auto",
      position: "relative",
      top: "-3px",
    },
    color = disabled ? "#A0A0A0" : "#404040";

  return <FontAwesomeIcon icon={Rotate} color={color} size="2x" className="RotateIcon" style={iconStyles} {...props} />;
};
RotateIcon.propTypes = {
  disabled: PropTypes.bool,
}
export default RotateIcon;
