import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';
import { batch } from "react-redux";
import { toast } from 'react-toastify';
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Patient } from "./Patient";

interface PatientsState {
  isLoading: boolean;
  isTimerActive: boolean;
  errorMessage: string | undefined;
  patients: Patient[];
  selectedPatient: Patient | undefined;
  retrieved: string | undefined;
}

const initialState: PatientsState = {
  isLoading: false,
  isTimerActive: false,
  errorMessage: undefined,
  patients: [],
  retrieved: undefined,
  selectedPatient: undefined
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<PatientsState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<PatientsState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setPatients(state, action: PayloadAction<PatientsState["patients"]>) {
      state.patients = action.payload;
    },
    setSelectedPatient(state, action: PayloadAction<PatientsState["selectedPatient"]>) {
      state.selectedPatient = action.payload;
    },
    setSelectedPatientToNextPatient(state) {
      if (state.patients) {
        const selectedPatientId = state.selectedPatient?.id;
        if (selectedPatientId && state.patients.length > 0) {
          const index = state.patients.findIndex(x => x.id === selectedPatientId);
          const nextPatient = index >= 0 && index < state.patients.length - 1 ? state.patients[index + 1] : state.patients[0];
          state.selectedPatient = nextPatient;
        } else {
          state.selectedPatient = state.patients[0];
        }
      } else {
        state.selectedPatient = undefined;
      }
    },
    setRetrieved(state, action: PayloadAction<PatientsState["retrieved"]>) {
      state.retrieved = action.payload;
    },
    setTimer(state, action: PayloadAction<PatientsState["isTimerActive"]>) {
      state.isTimerActive = action.payload;
    },
  },
});

export const patientsReducer = patientsSlice.reducer;

const { setPatients, setLoading, setErrorMessage, setRetrieved, setSelectedPatient, setSelectedPatientToNextPatient, setTimer } = patientsSlice.actions;

export { setSelectedPatient, setSelectedPatientToNextPatient };

export const fetchPatients = (communityId: string): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });

  httpClient.get(`community/${encodeURIComponent(communityId)}/patients`)
    .then((response) => {
      if (response.status === 204) {
        dispatch(setLoading(false));
        return;
      }
      const patients = response.data;

      if (patients && patients.filter((x: Patient) => !x.stream_active).length > 0) {
        dispatch(setPatientRefreshTimer(true, () => dispatch(refreshPatients(communityId))));
      }

      batch(() => {
        dispatch(setRetrieved(moment(new Date()).format('YYYYMMDDHHmmss')));
        dispatch(setPatients(patients));
        dispatch(setLoading(false));
      });
    })
    .catch(() => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the patients list"));
      });
    });
};

export const refreshPatients = (communityId: string): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(setErrorMessage(undefined));
  });
  try {
    const lastRefreshed = getState()?.patients.retrieved || '';

    const now = moment(new Date());
    if (lastRefreshed && now.diff(moment(lastRefreshed, 'YYYYMMDDHHmmss'), "seconds") < 5) {
      return;
    }

    const currentPatients = getState()?.patients.patients;
    const response = await httpClient.get<Patient[]>(`community/${encodeURIComponent(communityId)}/patients`);
    const updatedPatients = response.data;

    if (JSON.stringify(currentPatients) !== JSON.stringify(updatedPatients)) {
      dispatch(setPatients(updatedPatients));
      const selectedPatient = getState()?.patients.selectedPatient;
      if (selectedPatient) {
        const updatedPatient = updatedPatients.find(x => x.id === selectedPatient.id);
        if (JSON.stringify(selectedPatient) !== JSON.stringify(updatedPatient)) {
          dispatch(setSelectedPatient(updatedPatient));
        }
      }
    }

    if (updatedPatients && updatedPatients.filter((x: Patient) => !x.stream_active).length > 0) {
      dispatch(setPatientRefreshTimer(true, () => dispatch(refreshPatients(communityId))));
    }
    dispatch(setRetrieved(moment(new Date()).format('YYYYMMDDHHmmss')));


  } catch (err) {
    batch(() => {
      toast.error("There was an error while trying to refresh the patients");
      dispatch(setErrorMessage("There was an error while trying to refresh the patients"));
    });
  }
};

export const refreshPatient = (communityId: string, patientId: number): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  try {
    const selectedPatient = getState()?.patients.selectedPatient;
    const response = await httpClient.get(`community/${encodeURIComponent(communityId)}/patient/${encodeURIComponent(patientId)}`);
    if (response.status === 204) {
      dispatch(setLoading(false));
      return;
    }
    const patient = response.data;

    batch(() => {
      if (selectedPatient === undefined || patient.id === selectedPatient.id) {
        dispatch(setSelectedPatient(patient));
      }
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(setLoading(false));
      dispatch(setErrorMessage("There was an error while trying to refresh the patient"));
    });
  }
};
export const deactivateRefreshTimer = (): AppThunk => async (dispatch) => {
  dispatch(setTimer(false));
}

export const setPatientRefreshTimer = (activate: boolean, action: () => void): AppThunk => async (dispatch, getState) => {
  const isTimerActive = getState()?.patients.isTimerActive;
  if (isTimerActive === activate) {
    return;
  }
  let timeout: NodeJS.Timeout;
  if (activate) {
    dispatch(setTimer(true));
    timeout = setTimeout(() => {
      dispatch(setTimer(false));
      action();
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };

  }
};

export const patientsSelector = (state: RootState) => state.patients.patients;
export const patientsIsLoadingSelector = (state: RootState) => state.patients.isLoading;
export const patientsErrorMessageSelector = (state: RootState) => state.patients.errorMessage;
export const selectedPatientsSelector = (state: RootState) => state.patients.selectedPatient;
