import { Paper, Dialog as UnstyledDialog } from "@mui/material";
import styled from '@mui/styled-engine';
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import videojs from "video.js";
import Config from "../../../config";
import { Loader } from "../../components/Loader/Loader";
import { ZoomTransition } from "../../components/Transition/Transition";
import { getSelectedLanguage } from "../../hooks/useLanguage";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authTokenSelector, authUserSelector } from "../Auth/authSlice";
import { familyIsActiveSelector, selectedFamilySelector } from "../Families/familySlice";
import { fetchPatients, patientsIsLoadingSelector, selectedPatientsSelector, setSelectedPatient, setSelectedPatientToNextPatient } from "../Patients/patientsSlice";
import { MessageType, VideoMessage } from "./Components/VideoMessage";
import { VideoPlayer } from "./Components/VideoPlayer";
import "./skin.css";

const Dialog = styled(UnstyledDialog)(({ theme }: any) => ({
  padding: theme.spacing(0, 0, 0),
  display: "inline-block",
  height: "100%",
}));

const SimplePaper = styled(Paper)(() => ({
  boxShadow: 'none',
  padding: '0',
}));

export const Video = () => {
  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("video"));
  const dispatch = useAppDispatch();
  const selectedLanguage = getSelectedLanguage();
  const selectedPatient = useAppSelector(selectedPatientsSelector);
  const isLoading = useAppSelector(patientsIsLoadingSelector);
  const token = useAppSelector(authTokenSelector);
  const user = useAppSelector(authUserSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const [showDialog, setShowDialog] = useToggle(selectedPatient !== undefined);
  const isFamilyActive = useAppSelector(familyIsActiveSelector);

  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchPatients(selectedFamily.community_id));
    }
  }, [dispatch, selectedFamily]);

  useEffect(() => {
    setShowDialog(selectedPatient !== undefined);
    if (selectedPatient === undefined && showDialog) {
      document.documentElement.style.overflow = 'unset';
    }
  }, [selectedPatient, setShowDialog, showDialog]);

  const videoJsOptions: videojs.PlayerOptions = {
    autoplay: true,
    controls: false,
    loop: true,
    muted: true,
    preload: 'auto',
    controlBar: {
      fullscreenToggle: true,
    },
  };

  return (
    <Dialog fullScreen={true} open={showDialog} aria-labelledby="form-dialog-title" id="modal-add"
      TransitionComponent={ZoomTransition}
      PaperComponent={SimplePaper}>
      <Loader isLoading={isLoading} />
      {selectedPatient && (selectedPatient.stream_active || selectedPatient.wowza_stream_file?.startsWith("http") ?
        <VideoPlayer videoJsOptions={
          {
            ...videoJsOptions, sources:
              [{
                src: selectedPatient.wowza_stream_file.startsWith("http") ? selectedPatient.wowza_stream_file : Config.mediaUrl + "/" + selectedPatient.wowza_stream_file + ".stream/playlist.m3u8?t=" + token?.replace(/\./g, '___'),
                type: "application/x-mpegURL"
              }]
          }} patientId={selectedPatient.id} patientNumber={selectedPatient.patient_number}
          title={selectedPatient.first_name}
          identityId={user?.identity_id}
          nextAction={() => dispatch(setSelectedPatientToNextPatient())}
          exitFullScreenAction={() => dispatch(setSelectedPatient(undefined))}
          isActive={selectedPatient.wowza_stream_file.startsWith("http") ? true : selectedPatient.stream_active}
        />
        : <VideoMessage
          nextAction={() => dispatch(setSelectedPatientToNextPatient())}
          exitFullScreenAction={() => dispatch(setSelectedPatient(undefined))}
          messageType={selectedPatient.state === 'admitted' && isFamilyActive ? MessageType.Busy : MessageType.Discharged} />)
      }
    </Dialog>
  );
};
