import { createUserManager } from 'redux-oidc';
import { UserManagerSettings } from "oidc-client";
import config from "../../config";


const settings: UserManagerSettings = {
  authority: config.authentication.authority,
  client_id: config.authentication.clientId,
  redirect_uri: config.authentication.redirectUri,
  silent_redirect_uri: config.authentication.silentRedirectUri,
  post_logout_redirect_uri: config.authentication.postLogoutRedirectUri,
  response_type: config.authentication.responseType,
  scope: config.authentication.scope,
};
const userManager = createUserManager(settings);

export default userManager;