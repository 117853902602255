import { Box, Paper } from "@mui/material";
import styled from '@mui/styled-engine';
import moment from "moment";
import "moment/locale/nl";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GridWithTitle } from "../../components/Layout/GridWithTitle";
import { getSelectedLanguage } from "../../hooks/useLanguage";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authUserSelector } from "../Auth/authSlice";
import { FamilyDetail } from "./Tables/FamilyDetail";
import { familiesWithDetailsSelector, fetchFamiliesOfMemberWithDetails, fetchFamily } from "./familySlice";

const PaperFamily = styled(Paper)(({ theme }: any) => ({
    zIndex: 2,
    PointerEvent: "none",
    position: "relative",
    borderRadius: "35px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
}));

export const FamilySelect = () => {
    const { t } = useTranslation();
    useTitle(t("Babywatch"));
    const dispatch = useAppDispatch();
    const selectedLanguage = getSelectedLanguage();
    const navigate = useNavigate();

    const user = useAppSelector(authUserSelector);
    const families = useAppSelector(familiesWithDetailsSelector);

    useEffect(() => {
        moment.locale(selectedLanguage);
    }, [selectedLanguage]);

    useEffect(() => {
        dispatch(fetchFamiliesOfMemberWithDetails());
    }, [dispatch, user]);

    const selectCommunity = (communityId: string) => {
        console.debug("community selected: " + communityId);
        dispatch(fetchFamily(communityId));
        navigate("/family");
    };

    return (
        <GridWithTitle title={t("families")}>
            <Box>
                <p>{t("selectfamily")}</p>

                {families?.map((family: FamilyDetail) =>
                    <PaperFamily key={family.id} onClick={() => selectCommunity(family.community_id)}>
                        {t("family")}  {family.family_name}
                        &nbsp;:&nbsp;
                        {family.patient_names?.join(" & ")}

                        {user?.communities?.some(c => c.community_id === family.community_id && !c.is_active) ? " (" + t("Community deactivated") + ")" : ""}
                    </PaperFamily>

                )}

            </Box>
        </GridWithTitle>
    );
};
