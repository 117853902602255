import { combineReducers } from "@reduxjs/toolkit";
import { reducer as oidcReducer } from "redux-oidc";
import { appHeaderReducer } from "../components/AppHeader/appHeaderSlice";
import { authReducer } from "../domain/Auth/authSlice";
import { chatsReducer } from "../domain/Chats/chatSlice";
import { familiesReducer } from "../domain/Families/familySlice";
import { membersReducer } from "../domain/Members/membersSlice";
import { messagesReducer } from "../domain/Messages/messagesSlice";
import { notificationsReducer } from "../domain/Notifications/notificationsSlice";
import { patientsReducer } from "../domain/Patients/patientsSlice";
import { settingsReducer } from "../domain/Settings/settingsSlice";
import { usersReducer } from "../domain/Users/userSlice";
import { videoReducer } from "../domain/Video/videoSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  members: membersReducer,
  messages: messagesReducer,
  patients: patientsReducer,
  appHeader: appHeaderReducer,
  oidc: oidcReducer,
  chats: chatsReducer,
  families: familiesReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  video: videoReducer,
  users: usersReducer,
});

export default rootReducer;
