import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { toast } from 'react-toastify';
import { fetchUser } from "../../domain/Auth/authSlice";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { User } from "../Auth/Auth";

export interface IAvatar {
  content: string;
}
interface SettingState {
  isLoading: boolean;
  errorMessage: string | undefined;
  user: Partial<User> | undefined;
  selectedAvatar: IAvatar | undefined;
  showAvatarEditor: boolean;
  avatar: IAvatar | undefined;
  openSmsVerification: User | undefined;
  verificationCode: string | undefined;
}

const initialState: SettingState = {
  isLoading: false,
  errorMessage: undefined,
  user: undefined,
  selectedAvatar: undefined,
  showAvatarEditor: false,
  avatar: undefined,
  openSmsVerification: undefined,
  verificationCode: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<SettingState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<SettingState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setUser(state, action: PayloadAction<SettingState["user"]>) {
      state.user = action.payload;
    },
    closeOpenSettingsUser(state) {
      if (state.user !== undefined) {
        state.user.identity_id = undefined;
      }
    },
    setSelectedAvatar(state, action: PayloadAction<SettingState["selectedAvatar"]>) {
      state.selectedAvatar = action.payload;
    },
    /** Makes the avatar image editor visible showing the given image. */
    showAvatarEditor(state, action: PayloadAction<SettingState["selectedAvatar"]>) {
      state.selectedAvatar = action.payload;
      state.showAvatarEditor = true;
    },
    /** Makes the avatar image editor invisible. */
    hideAvatarEditor(state) {
      state.showAvatarEditor = false;
    },
    setAvatar(state, action: PayloadAction<SettingState["avatar"]>) {
      if (state.user !== undefined) {
        state.user.avatar = action.payload;
      }
    },
    setVerificationCode(state, action: PayloadAction<SettingState["verificationCode"]>) {
      if (state.user !== undefined) {
        state.verificationCode = action.payload;
      }
    },
    setOpenSmsVerification(state, action: PayloadAction<SettingState["openSmsVerification"]>) {
      state.openSmsVerification = action.payload;
    },
  },
});

export const settingsReducer = settingsSlice.reducer;

const { setUser, setLoading, setErrorMessage, setAvatar, setSelectedAvatar, showAvatarEditor, hideAvatarEditor, closeOpenSettingsUser, setOpenSmsVerification, setVerificationCode } = settingsSlice.actions;
export { closeOpenSettingsUser, hideAvatarEditor, setAvatar, setOpenSmsVerification, setSelectedAvatar, setUser, setVerificationCode, showAvatarEditor };

export const updateAvatar = (avatar: string): AppThunk => async (dispatch) => {
  dispatch(setUser({ profile_image: avatar }));
}

export const validateUser = async (userIdentityId: string, user: User): Promise<boolean> => {
  setLoading(true);
  return httpClient.get(`user/exist?email=${encodeURIComponent(user.email)}`)
    .then((response) => {
      setLoading(false);
      const responseId: string = response.data.identity_id as string;
      return response.data && (responseId === userIdentityId);
    })
    .catch((error) => {
      batch(() => {
        setLoading(false);
        setErrorMessage("There was an error while validating the user");
      });
      toast.error(error.response?.data.message || error.message);
      return false;
    });
};


export const updateUser = (userIdentityId: string, user: User): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(undefined));
  });

  const verificationCode = getState().settings.verificationCode;
  httpClient.put(`user/${userIdentityId}?code=${verificationCode}`, user)
    .then(() => {
      batch(() => {
        dispatch(fetchUser());
        dispatch(setLoading(true));
      });

    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the user"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const sendSmsCode = async (userIdentityId: string): Promise<boolean> => {
  setLoading(true);
  httpClient.post(`user/SendSmsCode/${userIdentityId}`)
    .then((response) => {
      setLoading(false);
      return (response.status === 204 || response.status === 200);
    })
    .catch((error) => {
      batch(() => {
        setLoading(false);
        setErrorMessage("There was an error while sending the sms");
      });

      toast.error(error.response?.data.message || error.message);
      return false;
    });
  return false;
};

export const validateSmsCode = async (userIdentityId: string, code: string): Promise<boolean> => {
  setLoading(true);
  return httpClient.post(`user/ValidateSmsCode/${userIdentityId}?code=${code}`)
    .then((response) => {
      setLoading(false);
      if (response.status === 204) {
        return true;
      }
      else if (response.status === 200 && response.data) {
        return true;
      }
      return false;

    })
    .catch((error) => {
      batch(() => {
        setLoading(false);
        setErrorMessage("There was an error while sending the sms");
      });
      toast.error(error.response?.data.message || error.message);
      return false;
    });
};

export const userIsLoadingSelector = (state: RootState) => state.settings.isLoading;
export const userErrorMessageSelector = (state: RootState) => state.settings.errorMessage;
export const selectedAvatarSelector = (state: RootState) => state.settings.selectedAvatar;
export const showAvatarEditorSelector = (state: RootState) => state.settings.showAvatarEditor;
export const settingsUserSelector = (state: RootState) => state.settings.user;
export const openSmsVerificationSelector = (state: RootState) => state.settings.openSmsVerification;
