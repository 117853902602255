import moment from "moment";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const Age: FunctionComponent<{
    date: Date | string;
  }> = ({ date }) => {
    const { t } = useTranslation();
  
    const formatAge = (birthday: Date) => {
      const date = moment(birthday);
      const now = moment(new Date());
      const duration = moment.duration(now.diff(date));
  
      const days = duration.days();
      const months = duration.months();
      const years = duration.years();
  
      let yearsText = t("year");
      if (years > 1) yearsText = t("years");
  
      let monthsText = t("months");
      if (months === 1) monthsText = t("month");
  
      let daysText = t("days");
      if (days === 1) daysText = t("day");
  
      let result = "";
      if (years > 0) {
        result += `${years} ${yearsText}, `;
      }
      if (months > 0) {
        result += `${months} ${monthsText} ${t("and")} `;
      }
  
      result += `${days} ${daysText}`;
  
      return result;
    };
  
    const age = formatAge(new Date(date));
  
    return <>{age}</>;
  };