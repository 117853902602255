import { Button, Container as UnstyledContainer, Typography } from "@mui/material";
import styled from '@mui/styled-engine';
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles";
import useTitle from "../../hooks/useTitle";
import userManager from "../../utils/userManager";

const Container = styled(UnstyledContainer)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const Title = styled(Typography)(() => ({
  fontWeight: 600,
}));

const BackButton = styled(Button)(({ theme }: any) => ({
  ...styles.pinkBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
}));

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     display: "flex",
//     alignItems: "center",
//     flexDirection: "column",
//   },
//   title: {
//     fontWeight: 600,
//   },
//   backBtn: {
//     ...styles.pinkBackground,
//     padding: theme.spacing(1, 3),
//     fontSize: "1.125rem",
//     borderRadius: "2rem",
//     lineHeight: "2rem",
//     textDecoration: "none",
//   }
// }));

const signOut = async () => {
  await userManager.signoutRedirect();
};

export const Loading = () => {
  const { t } = useTranslation();
  // const classes = useStyles();
  useTitle(t("BabyWatch"));

  return (
    <Container>
      <Title variant="h5" color="primary" gutterBottom>
        {t("Loading")}
      </Title>
      <BackButton onClick={signOut} color="primary"> {t("Back to login")} </BackButton>
    </Container>
  );
};
