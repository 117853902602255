import { ArrowBack, Delete } from '@mui/icons-material';
import { AppBar, Box, Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField, Toolbar, Typography, Avatar as UnstyledAvatar, DialogActions as UnstyledDialogActions } from "@mui/material";
import styled from '@mui/styled-engine';
import { Form, Formik, FormikHelpers, FormikProps, Field as UnstyledField } from "formik";
import "moment/locale/nl";
import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import { authIsAdministratorSelector, authUserSelector } from "../../domain/Auth/authSlice";
import { selectedFamilySelector } from '../../domain/Families/familySlice';
import { Member } from "../../domain/Members/Member";
import { deleteMember, inviteMember, openMemberSelector, parentsSelector, setOpenMember, updateMember } from "../../domain/Members/membersSlice";
import { deleteUser, inviteUser, updateUser } from "../../domain/Users/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SlideOrZoomTransition } from "../Transition/Transition";

const MenuIconButton = styled(IconButton)(({ theme }: any) => ({
  marginRight: theme.spacing(2),
}));

const Spacing = styled("div")(({ theme }: any) => ({
  paddingTop: theme.spacing(7),
}));

const Title = styled("h6")`
  flexGrow: 1;
`

const InfoBox = styled(Box)(({ theme }: any) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const InfoBoxAtBottom = styled(InfoBox)(() => ({
  position: "fixed",
  bottom: 0,
}));

const CenteredFormControl = styled(FormControl)(() => ({
  alignItems: 'center',
}));

const Field = styled(UnstyledField)(({ theme }: any) => ({
  marginBottom: theme.spacing(2),
}));

const Avatar = styled(UnstyledAvatar)(({ theme }: any) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  cursor: "pointer",
}));

const NameLabel = styled("span")(() => ({
  fontWeight: "bold",
}));


const DialogActions = styled(UnstyledDialogActions)(() => ({
  display: "flex",
}));

function ConfirmationDialog(props: any) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog disableEscapeKeyDown maxWidth="md" aria-labelledby="confirmation-dialog-title" open={open} {...other} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose(event, reason)
      }
    }}>
      <DialogTitle id="confirmation-dialog-title">{t("Delete member")}?</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t("No")}
        </Button>
        <Button onClick={handleOk} id="button-confirm" color="primary">
          {t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const MemberModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openMember = useAppSelector(openMemberSelector);
  const user = useAppSelector(authUserSelector);
  const formRef = useRef<FormikProps<Member>>(null);

  const [showConfirmDialog, setShowConfirmDialog] = useToggle(false);
  const [showDialog, setShowDialog] = useToggle(openMember !== undefined);

  const isMemberToAdd = openMember?.role_name?.toLowerCase() === "parent" || openMember?.role_name?.toLowerCase() === "friend";
  const isNew = openMember?.id === undefined;
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const dialogPadding = isMobile ? "0px" : "16px";
  const title = t((isNew ? "add " : "view ") + (openMember?.role_name?.toLowerCase() || 'friend'));
  const info = t((isNew ? "add " : "view ") + (openMember?.role_name?.toLowerCase() || 'friend') + " info");


  const parents = useAppSelector(parentsSelector);
  const isAdmin = useAppSelector(authIsAdministratorSelector);
  let canDelete = isAdmin;
  if (user?.role_name?.toLowerCase() === "parent" && !canDelete) {
    if (openMember?.role_name?.toLowerCase() === "parent") {
      if (parents.length > 1) {
        canDelete = true;
      }
    }
    else {
      canDelete = true;
    }
  }

  useEffect(() => {
    setShowDialog(openMember !== undefined);
  }, [setShowDialog, openMember]);

  const removeMember = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowConfirmDialog(true);
  };

  const handleFormSubmit = async (member: Member, { setStatus, resetForm }: FormikHelpers<any>) => {
    if (!selectedFamily?.community_id) return;

    if (isNew) {
      if (user?.id) {
        isMemberToAdd
          ? dispatch(inviteMember(user.id, selectedFamily.community_id, member.role_name, member))
          : dispatch(inviteUser(user.id, member.role_name, member));
      }
    } else {
      if (member?.identity_id) {
        isMemberToAdd
          ? dispatch(updateMember(member.identity_id, selectedFamily.community_id, member))
          : dispatch(updateUser(member.identity_id, member));
      }
    }

    resetForm({});
    setStatus({ success: true });
  };

  const handleFormReset = () => {
    dispatch(setOpenMember(undefined));
  };

  const handleConfirmDialogClose = (member: Member | undefined) => {
    if (!selectedFamily?.community_id) return;

    if (member?.id) {
      isMemberToAdd
        ? dispatch(deleteMember(selectedFamily.community_id, member.id))
        : dispatch(deleteUser(member.id));
      formRef.current?.resetForm({});
    }

    setShowConfirmDialog(false);
  };

  return (
    <Dialog fullScreen={isMobile} open={showDialog} aria-labelledby="form-dialog-title" id="modal-add"
      TransitionComponent={SlideOrZoomTransition}
      PaperProps={{
        style: {
          padding: dialogPadding,
          boxShadow: 'none',
        },
      }}>
      <Formik innerRef={formRef} initialValues={{ first_name: "", last_name: "", email: "", avatar: { content: "" }, role_name: openMember?.role_name || "friend", ...openMember }} onSubmit={handleFormSubmit} onReset={handleFormReset} autoComplete="off">
        {({ values, handleChange }) => (
          <Form>
            <BrowserView>
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            </BrowserView>
            <MobileView>
              <AppBar position="fixed" elevation={0}>
                <Toolbar>
                  <MenuIconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    type="reset"
                    size="large">
                    <ArrowBack />
                  </MenuIconButton>
                  <Title>
                    {title}
                  </Title>
                  {!isNew && canDelete && (
                    <MenuIconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={removeMember}
                      size="large">
                      <Delete />
                    </MenuIconButton>
                  )}
                </Toolbar>
              </AppBar>
              <Spacing />
            </MobileView>

            <DialogContent>

              {isNew && (
                <>
                  <InfoBox fontWeight="fontWeightLight" fontSize="fontSize" fontStyle="italic">
                    {info}
                  </InfoBox>
                  <Field
                    component={TextField}
                    margin="dense"
                    onChange={handleChange("first_name")}
                    value={values.first_name}
                    name="first_name"
                    id="input-firstname"
                    label={t("firstname")}
                    fullWidth
                    required
                  />
                  <Field
                    component={TextField}
                    margin="dense"
                    onChange={handleChange("last_name")}
                    value={values.last_name}
                    name="lastname"
                    id="input-lastname"
                    label={t("lastname")}
                    fullWidth
                    required
                  />
                  <Field
                    component={TextField}
                    margin="dense"
                    onChange={handleChange("email")}
                    value={values.email}
                    name="email"
                    id="input-email"
                    label={t("email")}
                    type="email"
                    fullWidth
                    required
                  />
                </>)}


              {!isNew && (
                <>
                  <CenteredFormControl variant="standard" fullWidth>
                    <Avatar src={values.avatar?.content ? `data:image/jpeg;base64,${values.avatar.content}` : ''} />
                  </CenteredFormControl>
                  <CenteredFormControl variant="standard" fullWidth>
                    <NameLabel>
                      {values.first_name} {values.last_name}
                    </NameLabel>
                    <Typography>
                      {values.email}
                    </Typography>
                    <BrowserView>
                      <InfoBox fontWeight="fontWeightLight" fontSize="fontSize" fontStyle="italic">
                        {info}
                      </InfoBox>
                    </BrowserView>
                  </CenteredFormControl>
                  <MobileView>
                    <InfoBoxAtBottom fontWeight="fontWeightLight" fontSize="fontSize" fontStyle="italic">
                      {info}
                    </InfoBoxAtBottom>
                  </MobileView>

                </>)}


            </DialogContent>

            <DialogActions>
              <BrowserView>
                {!isNew && canDelete && (
                  <Button id="button-delete" onClick={removeMember}>
                    {t("delete")}
                  </Button>
                )}
                <Button type="reset" variant={isNew ? undefined : "contained"} color={isNew ? undefined : "primary"}>
                  {t("cancel")}
                </Button>
              </BrowserView>
              {isNew && (
                <Button variant="contained" id="button-save" color="primary" type="submit">
                  {t("save")}
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
      <ConfirmationDialog id="confirmdialog" keepMounted open={showConfirmDialog} onClose={handleConfirmDialogClose} value={openMember} />
    </Dialog>
  );
};
