import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import i18n from "i18next";
import React from "react";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMount } from "react-use";
import { loadUser, OidcProvider } from "redux-oidc";
import config from "../config";
import "./App.css";
import { AppRoutes } from "./AppRoutes";
import { Settings } from "./domain/Settings/Settings";
import { getSelectedLanguage, setSelectedLanguage } from "./hooks/useLanguage";
import { store } from "./store/store";
import en from "./translations/en";
import nl from "./translations/nl";
import { httpClient } from "./utils/httpClient";
import ls from "./utils/localStorageKey";
import userManager from "./utils/userManager";


if (config.env !== "production") {
  const log = console.error.bind(console);
  console.error = (...args: Parameters<typeof console.error>) => {
    if (
      args[0] !==
      "Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://fb.me/react-strict-mode-find-node%s" ||
      args[1] !== "findDOMNode"
    ) {
      log(...args);
    }
  };
}

const selectedTenantLocalStorage = localStorage.getItem("selectedTenant");
if (selectedTenantLocalStorage && selectedTenantLocalStorage !== "null") {
  httpClient.defaults.baseURL = config.apiUrl.replace(/http(s)?:\/\//g, (matched) => `${matched}${JSON.parse(selectedTenantLocalStorage).subdomain}.`);
} else {
  httpClient.defaults.baseURL = config.apiUrl;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { en, nl },
    lng: getSelectedLanguage(),
    fallbackLng: "nl",
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.info("Missing translation", { lngs, ns, key, fallbackValue });
    },
  })
  .catch(console.error);

export const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
      h1: {
        fontFamily: "'Varela Round'",
      },
      h3: {
        lineHeight: 0.9,
        fontWeight: 700,
        fontSize: "1.725rem",
      },
      h4: {
        fontSize: "1.325rem",
        fontWeight: 600,
      },
      h5: {
        // display: "initial",
        fontSize: "1.125rem",
        textTransform: "uppercase",

        // padding: "0.5rem",
        // fontWeight: "bold"
      },
      body2: {
        fontSize: "0.75rem",
      },
      // const varelaFont =  "'Varela+Round'";
      // const montserratFont =  "Montserrat";
    },
    palette: {
      // mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: "#47446b",
        // main: "#04a7ff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#e95a88",
      },
      background: {
        default: "#efefef",
      },
    },
    shape: {
      // borderRadius: 45,
    },
  });


  const selectedLanguage = localStorage.getItem(ls("selectedLanguage")) ?? navigator.language.split("-")[0];

  useMount(() => {
    loadUser(store, userManager);
    setSelectedLanguage(selectedLanguage === "nl" ? "nl" : "en");
  });

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <>
            <CssBaseline />

            <BrowserRouter>
              <OidcProvider userManager={userManager} store={store}>
                <AppRoutes />
              </OidcProvider>
            </BrowserRouter>
            <Settings />

          </>
          <ToastContainer autoClose={8000} />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};
