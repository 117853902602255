import { Grid } from "@mui/material";
import styled from '@mui/styled-engine';
import React, { FunctionComponent, PropsWithChildren } from "react";

const IconImage = styled("img")(({ theme }: any) => ({
  maxHeight: "7vh",
  margin: theme.spacing(1),
}));

export const FamilyLayout: FunctionComponent<PropsWithChildren<{
  icon?: string;
}>> = ({ icon, children }) => {
  return (
    <Grid container>
      <Grid item xs={3} lg={2}
        container
        direction="column"
        alignItems="center"
        justifyContent="center">
        {icon && <IconImage src={icon} alt="" />}
      </Grid>
      <Grid item xs={9} lg={10}
        container
        direction="column"
        // alignItems="center"
        justifyContent="center">
        {children}
      </Grid>
    </Grid>
  );
};
