import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';
import { batch } from "react-redux";
import { toast } from "react-toastify";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Message } from "./Chat";

interface ChatsState {
  isLoading: boolean;
  errorMessage: string | undefined;
  messages: Message[];
  retrieved: string | undefined;
}

const initialState: ChatsState = {
  isLoading: false,
  errorMessage: undefined,
  messages: [],
  retrieved: undefined
};

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<ChatsState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<ChatsState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setMessages(state, action: PayloadAction<ChatsState["messages"]>) {
      state.messages = action.payload;
    },
    setRetrieved(state, action: PayloadAction<ChatsState["retrieved"]>) {
      state.retrieved = action.payload;
    },
  },
});

export const chatsReducer = chatsSlice.reducer;

const { setMessages, setLoading, setErrorMessage, setRetrieved } = chatsSlice.actions;

export const addChatMessage = (communityId: string, message: Message): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  httpClient.post(`chat/${communityId}`, message)
    .then(() => {
      batch(() => {
        dispatch(fetchChatMessages(communityId));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while adding the chat message"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const addFavoriteChatMessage = (communityId: string, message: Message): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  httpClient.put(`chat/${message.id}/favorite`, message?.user)
    .then(() => {
      batch(() => {
        dispatch(fetchChatMessages(communityId));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the chat messages"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const removeFavoriteChatMessage = (communityId: string, message: Message): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  httpClient.put(`chat/${message.id}/remove-favorite`, message?.user)
    .then(() => {
      batch(() => {
        dispatch(fetchChatMessages(communityId));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the chat messages"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const fetchChatMessages = (communityId: string): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(setLoading(true));
  });
  const fromDate = getState()?.chats.retrieved || '';
  httpClient.get<Message[]>(`chat/${encodeURIComponent(communityId)}?fromDate=${encodeURIComponent(fromDate)}`)
    .then((response) => {
      if (response.status === 204) {
        dispatch(setLoading(false));
        return;
      }
      const messages = response?.data ?? [];

      batch(() => {
        dispatch(setRetrieved(moment(new Date()).format('YYYYMMDDHHmmss')));
        dispatch(setMessages(messages));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the chat messages"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const chatMessagesSelector = (state: RootState) => state.chats.messages;
export const chatsIsLoadingSelector = (state: RootState) => state.chats.isLoading;
export const chatsErrorMessageSelector = (state: RootState) => state.chats.errorMessage;
