import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';
import { batch } from "react-redux";
import { toast } from 'react-toastify';
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Member } from "./Member";
import { MemberDeviceDetail } from "./Tables/MemberDeviceDetail";
interface ParentsState {
  isLoading: boolean;
  errorMessage: string | undefined;
  openMember: Partial<Member> | undefined;
  parents: Member[];
  friends: Member[];
  members: Member[];
  memberDevices: MemberDeviceDetail[];
  retrieved: string | undefined;
}

const initialState: ParentsState = {
  isLoading: false,
  errorMessage: undefined,
  openMember: undefined,
  parents: [],
  friends: [],
  members: [],
  memberDevices: [],
  retrieved: undefined
};

export const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<ParentsState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<ParentsState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setOpenMember(state, action: PayloadAction<ParentsState["openMember"]>) {
      state.openMember = action.payload;
    },
    setParents(state, action: PayloadAction<ParentsState["parents"]>) {
      state.parents = action.payload.map(m => { return { ...m, role_name: "parent" } });
    },
    setFriends(state, action: PayloadAction<ParentsState["friends"]>) {
      state.friends = action.payload.map(m => { return { ...m, role_name: "friend" } });
    },
    setMembers(state, action: PayloadAction<ParentsState["members"]>) {
      state.members = action.payload;
    },
    setMemberDevices(state, action: PayloadAction<ParentsState["memberDevices"]>) {
      state.memberDevices = action.payload;
    },
    setRetrieved(state, action: PayloadAction<ParentsState["retrieved"]>) {
      state.retrieved = action.payload;
    },
  },
});

export const membersReducer = membersSlice.reducer;

const { setParents, setFriends, setMembers, setLoading, setErrorMessage, setOpenMember, setRetrieved, setMemberDevices } = membersSlice.actions;
export { setOpenMember };

export const fetchMembers = (communityId: string): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`community/${encodeURIComponent(communityId)}/members`)
    .then((response) => {
      if (response.status === 204) {
        dispatch(setLoading(false));
        return;
      }
      const parents = response.data["parents"];
      const friends = response.data["friends"];

      batch(() => {
        dispatch(setRetrieved(moment(new Date()).format('YYYYMMDDHHmmss')));
        dispatch(setParents(parents));
        dispatch(setFriends(friends));
        dispatch(setMembers(friends.concat(parents)));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const inviteMember = (userId: number, communityId: string, role: string, member: Member): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`family/${encodeURIComponent(communityId)}/email/${encodeURIComponent(member.email ?? "")}`)
    .then((response) => {
      if (response.data) {
        toast.error("emailExists");
        return;
      }

      httpClient.post(`user/${communityId}/invite/${role}`, member)
        .then(() => {
          batch(() => {
            dispatch(fetchMembers(communityId));
            dispatch(setOpenMember(undefined));
          });
        })
        .catch((error) => {
          batch(() => {
            dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
            toast.error(error.response?.data.message || error.message);
          });
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    });
}

export const updateMember = (memberId: string, communityId: string, member: Member): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`user/exist?email=${encodeURIComponent(member.email ?? "")}`)
    .then((existingUser) => {
      if (existingUser.data.first_name !== member.first_name || existingUser.data.last_name !== member.last_name || existingUser.data.email !== member.email) {
        httpClient.put(`user/${memberId}`, member)
          .then(() => {
            batch(() => {
              dispatch(fetchMembers(communityId));
              dispatch(setOpenMember(undefined));
            });
          })
          .catch((error) => {
            toast.error(error.response?.data.message || error.message);
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      } else {
        toast.error("emailExists");
        return;
      }
    })
    .catch((error) => {
      batch(() => {
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const deleteMember = (communityId: string, memberId: number): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.delete(`user/${memberId}/community/${communityId}`)
    .then(() => {
      batch(() => {
        dispatch(fetchMembers(communityId));
        dispatch(setOpenMember(undefined));
        dispatch(setLoading(true));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const fetchMemberDevices = (communityId: string | undefined): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`member/devices` + (communityId === undefined ? '' : `?community=${encodeURIComponent(communityId)}`))
    .then((response) => {
      batch(() => {
        dispatch(setMemberDevices(response.data));
        dispatch(setLoading(false));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    });
};

export const parentsSelector = (state: RootState) => state.members.parents;
export const friendsSelector = (state: RootState) => state.members.friends;
export const membersSelector = (state: RootState) => state.members.members;
export const membersIsLoadingSelector = (state: RootState) => state.members.isLoading;
export const membersErrorMessageSelector = (state: RootState) => state.members.errorMessage;
export const openMemberSelector = (state: RootState) => state.members.openMember;
export const memberDevicesSelector = (state: RootState) => state.members.memberDevices;
