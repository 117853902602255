import { User } from "oidc-client";
import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setLoggedInUser } from "../../domain/Auth/authSlice";
import { useAppDispatch } from "../../store/hooks";
import userManager from "../../utils/userManager";

export const Callback: FunctionComponent = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        userManager.signinRedirectCallback()
            .then((user) => successCallback(user))
            .catch((error) => errorCallback(error));
    });

    const successCallback = async (user: User) => {
        dispatch(setLoggedInUser(user));
        navigate("/");
    };

    const errorCallback = (error: Error) => {
        console.error(error);
    };

    return (
        <div>&#x231B;</div>
    );
};
