import { RootState } from "../../store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppHeaderState {
  showHeader: boolean;
}

const initialState: AppHeaderState = {
  showHeader: true,
};

export const appHeaderSlice = createSlice({
  name: "appHeader",
  initialState,
  reducers: {
    setAppHeader(state, action: PayloadAction<AppHeaderState["showHeader"]>) {
      state.showHeader = action.payload;
    },
  },
});

export const appHeaderReducer = appHeaderSlice.reducer;

const {
  setAppHeader,
} = appHeaderSlice.actions;
export { setAppHeader };

export const useAppHeaderSelector = (state: RootState) => state.appHeader.showHeader;
