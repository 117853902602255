import i18n from "i18next";
import ls from "../utils/localStorageKey";

export type LanguageName = "en" | "nl";

export interface Language {
  title: string;
  name: LanguageName;
  flag: string;
}

export const setSelectedLanguage = (language: LanguageName) => {
  localStorage.setItem(
    ls("selectedLanguage"),
    language
  );
  if (language) {
    i18n.changeLanguage(language).catch(console.error);
  }
  document.documentElement.lang = language === "nl" ? "nl" : "en";
};

export const getSelectedLanguage = (): LanguageName => {
  const navigatorLanguageAndCulture = navigator.language?.split("-");
  let navigatorLanguage: string | undefined = undefined;
  if (navigatorLanguageAndCulture?.length === 2) {
    navigatorLanguage = navigatorLanguageAndCulture[0];
  }
  const localStorageLanguage =
    localStorage.getItem(ls("selectedLanguage"));

  const languageAsString = localStorageLanguage ?? navigatorLanguage;

  return languageAsString === "en" ? "en" : "nl";
};
