import { Container as UnstyledContainer, Typography } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles";
import useTitle from "../../hooks/useTitle";
import logo from "./assets/logo.png";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const NotFoundMessage = styled(Typography)(({ theme }: any) => ({
  fontWeight: 600,
}));

const Logo = styled("img")(({ theme }: any) => ({
  maxWidth: "100%",
  paddingBottom: theme.spacing(1),
}));

const BackButton = styled("a")(({ theme }: any) => ({
  ...styles.pinkBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
}));

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     height: "100vh",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//   },
//   logo: {
//     maxWidth: "100%",
//     paddingBottom: theme.spacing(1),
//   },
//   notFoundMessage: {
//     fontWeight: 600,
//   },
//   backBtn: {
//     ...styles.pinkBackground,
//     padding: theme.spacing(1, 3),
//     fontSize: "1.125rem",
//     borderRadius: "2rem",
//     lineHeight: "2rem",
//     textDecoration: "none",
//   }
// }));

export const NotFound = () => {

  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("Page not found"));
  // const classes = useStyles();

  return (
    <Container>
      <Logo
        src={logo}
        alt="page not found logo"
      />
      <NotFoundMessage variant="h3" color="primary" gutterBottom>
        {t("Page not found")}
      </NotFoundMessage>
      <BackButton href="/" color="primary"> {t("Back to homepage")} </BackButton>
    </Container>
  );
};
