import { Box, Button, List, ListItem, Paper, Typography, Avatar as UnstyledAvatar } from "@mui/material";
import styled from '@mui/styled-engine';
import moment from "moment";
import "moment/locale/nl";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { iconBaby, iconFamilie, iconProfiel } from "../../assets/assets";
import { AddButton } from "../../components/AddButton/AddButton";
import { GridWithTitle } from "../../components/Layout/GridWithTitle";
import { Loader } from "../../components/Loader/Loader";
import { MemberModal } from "../../components/MemberModal/MemberModal";
import { getSelectedLanguage } from "../../hooks/useLanguage";
import useTitle from "../../hooks/useTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authIsAdministratorSelector, authUserSelector } from "../Auth/authSlice";
import { Member } from "../Members/Member";
import { fetchMembers, friendsSelector, membersIsLoadingSelector, parentsSelector, setOpenMember } from "../Members/membersSlice";
import { fetchPatients, patientsIsLoadingSelector, patientsSelector } from "../Patients/patientsSlice";
import { setUser } from "../Settings/settingsSlice";
import { FamilyLayout } from "./FamilyLayout";
import { selectedFamilySelector } from "./familySlice";

const Avatar = styled(UnstyledAvatar)(({ theme }: any) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  marginRight: theme.spacing(1),
}));

const Name = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "1rem",
  width: "100%",
}));

const UpdateButton = styled(Button)(({ theme }: any) => ({
  padding: theme.spacing(0.5),
  textAlign: "left",
  textTransform: "none",
}));

const RoundedLabel = styled(Typography)(() => ({
  borderRadius: 6,
  border: 0,
  color: "white",
  padding: "0 0.5rem 0 0.5rem",
  minWidth: "120px",
}));

const PurpleLabel = styled(RoundedLabel)(() => ({
  // ...colorBackground,
  background: "linear-gradient(45deg, #6a7eb8 30%, #56529b 90%)",
}));

const PinkLabel = styled(RoundedLabel)(() => ({
  // ...colorBackground,
  background: "linear-gradient(45deg, #f3a29a 30%, #e85887 90%)",
}));

const PaperParents = styled(Paper)(({ theme }: any) => ({
  zIndex: 2,
  PointerEvent: "none",
  position: "relative",
  borderRadius: "35px",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const PaperFriends = styled(Paper)(({ theme }: any) => ({
  background: "#dcdce3",
  zIndex: 0,
  position: "relative",
  top: "-70px",
  borderRadius: "35px",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(1),
}));

const Body = styled(Typography)(() => ({
  fontSize: "0.7rem",
}));

const MemberListItem: FunctionComponent<{
  member: Member;
  disabled?: boolean;
  onClick?: (member: Member) => void;
}> = ({ member, disabled, onClick }) => {
  const handleOnClick = (member: Member) => {
    if (onClick) onClick(member);
  };

  return (
    <ListItem disableGutters dense>
      <UpdateButton size="medium" id={member.email} onClick={() => handleOnClick(member)} disabled={disabled}>
        <Avatar alt={member.first_name?.toUpperCase()} src={member.avatar?.content ? `data:image/jpeg;base64,${member.avatar.content}` : '.'} />
        <Name color="primary">
          {member.first_name} {member.last_name}
        </Name>
      </UpdateButton>
    </ListItem>
  );
};

export const FamilyOverview = () => {
  const { t } = useTranslation();
  useTitle(t("Babywatch"));
  const dispatch = useAppDispatch();
  const selectedLanguage = getSelectedLanguage();

  const user = useAppSelector(authUserSelector);
  const parents = useAppSelector(parentsSelector);
  const membersIsLoading = useAppSelector(membersIsLoadingSelector);
  const patients = useAppSelector(patientsSelector);
  const friends = useAppSelector(friendsSelector);
  const patientsIsLoading = useAppSelector(patientsIsLoadingSelector);
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const isAdmin = useAppSelector(authIsAdministratorSelector);

  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchMembers(selectedFamily.community_id));
      dispatch(fetchPatients(selectedFamily.community_id));
    }
  }, [dispatch, selectedFamily]);

  return (
    <GridWithTitle title={t("family") + " " + selectedFamily?.family_name + (selectedFamily?.deactivation_date ? " (" + t("Community deactivated") + ")" : "")}>
      <Box>
        <PaperParents>
          <FamilyLayout icon={iconProfiel}>
            <List id="list-parent">
              <ListItem disableGutters dense>
                <PurpleLabel variant="h5" color="inherit">
                  {t("parents")}:
                </PurpleLabel>
                {(isAdmin || user?.communities.some(c => c.community_id === selectedFamily?.community_id && c.is_parent)) && <AddButton role="parent" />}
                <Loader isLoading={membersIsLoading} />
              </ListItem>
              {parents.map((member: Member) => (
                <MemberListItem key={member.id} member={member} onClick=
                  {
                    (member) =>
                      user?.identity_id === member.identity_id ?
                        dispatch(setUser(user))
                        :
                        dispatch(setOpenMember(member))
                  } />
              ))}
            </List>
          </FamilyLayout>
          <FamilyLayout icon={iconBaby}>
            <List>
              <ListItem disableGutters dense>
                <PurpleLabel variant="h5" color="inherit">
                  {t("babies")}:
                </PurpleLabel>
                <Loader isLoading={patientsIsLoading} />
              </ListItem>
              {patients.map((patient) => (
                <ListItem key={patient.id} disableGutters dense>
                  <Box key={patient.id} mb={1}>
                    <Typography variant="h5" color="primary">
                      {patient.first_name}
                    </Typography>
                    <Box>
                      <Body variant="body2">
                        {`${t("date_of_birth")}: `} <Moment date={new Date(patient.date_of_birth)} format="DD MMMM YYYY" locale={selectedLanguage} />
                      </Body>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </FamilyLayout>
        </PaperParents>
        <PaperFriends>
          <FamilyLayout icon={iconFamilie}>
            <List id="list-member">
              <ListItem disableGutters dense>
                <PinkLabel variant="h5" color="inherit">
                  {t("family")}:
                </PinkLabel>
                {(isAdmin || user?.communities.some(c => c.community_id === selectedFamily?.community_id && c.is_parent)) && <AddButton role="friend" />}
              </ListItem>
              {friends.map((member: Member) => (
                <MemberListItem key={member.id} member={member} onClick=
                  {
                    (member) =>
                      user?.identity_id === member.identity_id ?
                        dispatch(setUser(user))
                        :
                        dispatch(setOpenMember(member))
                  } />
              ))}
            </List>
            <MemberModal />
          </FamilyLayout>
        </PaperFriends>
      </Box>
    </GridWithTitle>
  );
};
