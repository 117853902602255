import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Avatar, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, styled } from "@mui/material";
import { PopupState } from "material-ui-popup-state/hooks";
import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { authUserSelector } from "../../domain/Auth/authSlice";
import { setUser } from "../../domain/Settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import userManager from "../../utils/userManager";

export const UserMenu: FunctionComponent<{
  popupState: PopupState;
}> = ({ popupState }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(authUserSelector);

  const signOut = async () => {
    await userManager.signoutRedirect();
  };

  const closeMenu = () => {
    popupState.setOpen(false);
  };

  const openSettings = () => {
    closeMenu();
    dispatch(setUser(user));
  };

  const WideList = styled(List)(({ theme }: any) => ({
    width: isMobile ? "320px" : "380px",
  }));

  return (
    <WideList aria-label="mailbox folders">
      <ListItem button onClick={openSettings}>
        <ListItemText id="text-profile" primary={`${user?.first_name} ${user?.last_name || ""}`} />
        <ListItemSecondaryAction>
          <Avatar src={user && user.avatar && user?.avatar?.content ? `data:image/jpeg;base64,${user.avatar.content}` : '.'} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <ListItem button onClick={signOut}>
        <ListItemText>{t("signout")}</ListItemText>
        <ListItemSecondaryAction>
          <IconButton edge="end" size="large">
            <ExitToAppIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </WideList>
  );
};
