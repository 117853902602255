import { Box, CircularProgress, styled } from "@mui/material";
import React, { FunctionComponent } from "react";


const LoaderBox = styled(Box)(({ theme }: any) => ({
  position: "fixed",
  left: 0,
  top: "40%",
  width: "100%",
  zIndex: 1001,
}));

export const Loader: FunctionComponent<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <LoaderBox display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </LoaderBox>
      )}
    </>
  );
}