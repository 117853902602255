import { Slide, SlideProps, Zoom, ZoomProps } from "@mui/material";
import React from "react";
import { isMobile } from 'react-device-detect';

export const SlideOrZoomTransition = React.forwardRef(function Transition(
  props: SlideProps & ZoomProps,
  ref: React.Ref<unknown>,
) {
  return isMobile ? <Slide direction="left" ref={ref} {...props} /> : <Zoom ref={ref} {...props} />;
});

export const ZoomTransition = React.forwardRef(function Transition(
  props: ZoomProps,
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});
