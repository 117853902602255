import UnknownProfile from "../../../assets/unknown_profile.jpg";
import fileType from "./file-type/index";

export function logTitle(message) {
  const consoleTitleStyles = ["background: black", "color: white", "padding: 2px"].join(";");

  console.log("%c" + message, consoleTitleStyles);
}

export function getFileTypeFromPreview(file) {
  const xhr = new XMLHttpRequest();

  return new Promise((resolve) => {
    xhr.open("GET", file.preview);
    xhr.responseType = "arraybuffer";

    xhr.onload = () => {
      resolve(fileType(new Uint8Array(xhr.response)));
    };

    xhr.send();
  });
}

export function getAttachmentFromPreview(preview) {
  const xhr = new XMLHttpRequest();

  return new Promise((resolve) => {
    xhr.open("GET", preview);
    xhr.responseType = "arraybuffer";

    xhr.onload = () => {
      resolve(new Uint8Array(xhr.response));
    };

    xhr.send();
  });
}

export function bufferToBase64(buf) {
  var binstr = Array.prototype.map
    .call(buf, function (ch) {
      return String.fromCharCode(ch);
    })
    .join("");
  return btoa(binstr);
}

// https://stackoverflow.com/questions/32702431/display-images-fetched-from-s3
export function encode(data) {
  let str = "";
  for (let i = 0; i < data.length; i++) {
    str = str + String.fromCharCode(data[i]);
  }

  // const str = data.reduce((a,b) => a + String.fromCharCode(b), '');
  return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
}

export function logError(message, component, method) {
  console.error(message);
  console.error(component + ": " + method);
}

export async function generateCarlSagan() {
  let image = new Image();

  const attachment = await getAttachmentFromPreview(UnknownProfile);

  const avatarFileType = await fileType(new Uint8Array(attachment));

  image.src = "data:" + avatarFileType.mime + ";base64," + encode(attachment);

  return image;
}
