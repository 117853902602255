import PropTypes from "prop-types";
import React, { Component } from "react";
import ImageEditor from "../ImageEditor/ImageEditor";
import { creaturePropType } from "../libs/propTypes";
import "./AvatarEditor.scss";

class AvatarEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarType: "image",
      creature: {
        form: {
          pattern: 0,
          colors: 0,
        },
        mouth: {
          pattern: 0,
          colors: 0,
        },
        eye: {
          pattern: 0,
          colors: 0,
        },
      },
      image: {
        file: null,
        zoom: 0,
        rotation: 0,
        position: {
          x: 0.5,
          y: 0.5,
        },
      },
    };

    this.props = props;
  }

  toggleImage = () => {
    this.setState({ avatarType: "image" });
  };

  toggleCreature = () => {
    this.setState({ avatarType: "creature" });
  };

  onClose() {
    this.props.onClose();
  }

  componentDidMount() {
    const { defaultAvatarType, avatarType, image, creature } = this.props;

    if (avatarType) {
      this.setState({ avatarType });
    } else if (image) {
      this.toggleImage();
    } else if (creature) {
      this.toggleCreature();
    } else if (defaultAvatarType) {
      this.setState({
        avatarType: defaultAvatarType,
      });
    } else if (this.props.defaultImage) {
      this.toggleImage();
    } else if (this.props.defaultCreature) {
      this.toggleCreature();
    }
  }

  render = () => {
    const {
      defaultImage,
      defaultZoom,
      defaultRotation,
      defaultPosition,

      forceImage,
      forceZoom,
      forceRotation,
      forcePosition,

      onUpdateImage,

      validAttachmentTypes,
      maxSize,
      onDropRejected,
      onError,
      onClose,
    } = this.props,
      { file, zoom, rotation, position } = this.state.image;

    return (
      <div className="AvatarEditor">
        <ImageEditor
          defaultImage={defaultImage}
          defaultZoom={defaultZoom}
          defaultRotation={defaultRotation}
          defaultPosition={defaultPosition}
          forceImage={forceImage}
          forceZoom={forceZoom}
          forceRotation={forceRotation}
          forcePosition={forcePosition}
          file={file}
          zoom={zoom}
          rotation={rotation}
          position={position}
          changeImageProperty={({ key, value }) =>
            this.setState((prevState) => ({
              image: {
                ...prevState.image,
                [key]: value,
              },
            }))
          }
          onUpdateImage={onUpdateImage}
          validAttachmentTypes={validAttachmentTypes}
          maxSize={maxSize}
          onDropRejected={onDropRejected}
          onError={onError}
          onClose={onClose}
        />
      </div>
    );
  };
}

AvatarEditor.propTypes = {
  defaultImage: PropTypes.string,
  defaultCreature: creaturePropType,
  defaultAvatarType: PropTypes.oneOf(["image", "creature"]),
  defaultZoom: PropTypes.number,
  defaultRotation: PropTypes.number,
  defaultPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  image: PropTypes.string,
  forceImage: PropTypes.string,
  forceZoom: PropTypes.number,
  forceRotation: PropTypes.number,
  forcePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  creature: creaturePropType,
  showCreates: PropTypes.bool,
  avatarType: PropTypes.oneOf(["image", "creature"]),
  zoom: PropTypes.number,
  rotation: PropTypes.number,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  onUpdateCreature: PropTypes.func,
  onUpdateImage: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,

  validAttachmentTypes: PropTypes.object,
  maxSize: PropTypes.number,
  onDropRejected: PropTypes.func,
};

export default AvatarEditor;
