import { faTrash as TrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./TrashIcon.scss";

const TrashIcon = (props) => {
  const iconStyles = {
    cursor: "pointer",
    marginLeft: "10px",
    position: "relative",
    top: "-3px",
  };
  return <FontAwesomeIcon icon={TrashCan} color="#404040" size="2x" className="TrashIcon" style={iconStyles} {...props} />;
};

export default TrashIcon;
