import { Button, Typography, Container as UnstyledContainer } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React from "react";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import styles from "../../../styles";
import useTitle from "../../hooks/useTitle";
import { useAppSelector } from "../../store/hooks";
import { httpClient } from "../../utils/httpClient";
import userManager from "../../utils/userManager";
import { authUserSelector } from "../Auth/authSlice";
import { selectedFamilySelector } from "../Families/familySlice";
import logo from "./assets/logo.png";

const Container = styled(UnstyledContainer)(({ theme }: any) => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const NotFoundMessage = styled(Typography)(({ theme }: any) => ({
  fontWeight: 600,
  textAlign: "center",
}));

const Logo = styled("img")(({ theme }: any) => ({
  maxWidth: "100%",
  paddingBottom: theme.spacing(1),
}));

const Buttons = styled("div")(({ theme }: any) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const BackButton = styled(Button)(({ theme }: any) => ({
  ...styles.pinkBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
  marginTop: "0.5rem",
}));

const DownloadButton = styled(Button)(({ theme }: any) => ({
  ...styles.purpleBackground,
  padding: theme.spacing(1, 3),
  fontSize: "1.125rem",
  borderRadius: "2rem",
  lineHeight: "2rem",
  textDecoration: "none",
}));

const signOut = async () => {
  await userManager.signoutRedirect();
};

const download = async (communityId: any) => {
  const selectedTenantLocalStorage = localStorage.getItem("selectedTenant");
  let baseURL: string;
  if (selectedTenantLocalStorage && selectedTenantLocalStorage !== "null") {
    baseURL = config.apiUrl.replace(/http(s)?:\/\//g, (matched) => `${matched}${JSON.parse(selectedTenantLocalStorage).subdomain}.`);
  } else {
    baseURL = config.apiUrl;
  }

  const urlPath = `file/base64/pdf/${encodeURIComponent(communityId)}`;
  const url = baseURL + "/" + urlPath;
  httpClient.get(url);
  window.open(
    url,
    "_blank"
  );
};

export const NotActive = () => {

  const { t } = useTranslation();
  useTitle(t("BabyWatch") + " - " + t("Community deactivated"));
  const user = useAppSelector(authUserSelector);

  const selectedFamily = useAppSelector(selectedFamilySelector);
  const downloadAvailable = true;

  const isParentInThisCommunity = user?.communities.some(c => c.community_id === selectedFamily?.community_id && c.is_parent);

  return (
    <Container>
      <Logo
        src={logo}
        alt="page not found logo"
      />
      <NotFoundMessage variant="h4" color="primary" gutterBottom> {isParentInThisCommunity && downloadAvailable ? (t("Download Message")) : (t("Not Active Message"))}</NotFoundMessage>
      <br />
      {downloadAvailable ? (
        <Buttons>
          {isParentInThisCommunity && selectedFamily?.community_id &&
            <DownloadButton onClick={() => download(selectedFamily.community_id)} color="primary"> {t("Download PDF")} </DownloadButton>
          }
          <BackButton onClick={signOut} color="primary"> {t("Back to login")} </BackButton>
        </Buttons>
      ) : (
        <BackButton onClick={signOut} color="primary"> {t("Back to login")} </BackButton>
      )}
    </Container>
  );
};
