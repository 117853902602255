import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Notification } from "./Notification";


interface NotificationState {
  isLoading: boolean;
  errorNotification: string | undefined;
  notifications: Notification[];
}

const initialState: NotificationState = {
  isLoading: false,
  errorNotification: undefined,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<NotificationState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorNotification(
      state,
      action: PayloadAction<NotificationState["errorNotification"]>
    ) {
      state.errorNotification = action.payload;
    },
    setNotifications(state, action: PayloadAction<NotificationState["notifications"]>) {
      state.notifications = action.payload;
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;

const {
  setNotifications,
  setLoading,
  setErrorNotification,
} = notificationsSlice.actions;


export const fetchNotifications = (identityId: string): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorNotification(undefined));
  });
  try {
    const route = `notification/${identityId}`;
    const response = await httpClient.get(route);
    const notifications = response.data;
    batch(() => {
      dispatch(setNotifications(notifications));
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(setLoading(false));
      dispatch(
        setErrorNotification(
          "There was an error while trying to fetch the notifications list"
        )
      );
    });
    console.error(err);
  }
};

export const notificationsSelector = (state: RootState) => state.notifications.notifications;
export const notificationsIsLoadingSelector = (state: RootState) =>
  state.notifications.isLoading;
export const notificationsErrorNotificationSelector = (state: RootState) =>
  state.notifications.errorNotification;
