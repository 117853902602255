import { Grid, Typography as UnstyledTypography } from "@mui/material";
import styled from '@mui/styled-engine';
import "moment/locale/nl";
import React from "react";
import { isMobile } from "react-device-detect";

const Typography = styled(UnstyledTypography)(() => ({
    textTransform: "uppercase",
}));

const TitleGrid = styled(Grid)(() => ({
    maxHeight: "50px",
}));

const ContainerGrid = styled(Grid)(() => ({
    height: isMobile ? "calc(100% - 8px)" : "100%",
    paddingLeft: isMobile ? "8px" : "108px",
    paddingRight: "8px",
    paddingBottom: "8px",
    flexDirection: "column",
    flexWrap: "nowrap",
}));

/**
 * A simple reusable flex grid.
 * 
 * @param props 
 * @returns 
 */
export const GridWithTitle = (props: { title?: string, children: React.ReactNode }) => {
    return (
        <ContainerGrid container>
            {
                props.title ?
                    <TitleGrid item xs={12}>
                        <Typography variant="h3" color="primary" gutterBottom>
                            {props.title}
                        </Typography>
                    </TitleGrid>
                    :
                    <></>
            }

            <Grid item xs={12}>
                {props.children}
            </Grid>
            <Grid item xs={12} sx={{ maxHeight: "50px" }}>

            </Grid>

        </ContainerGrid>
    );
};
