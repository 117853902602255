
export class HttpClient {
    defaults: { baseURL?: string, headers: { common: any } }

    constructor() {
        this.defaults = { headers: { common: {} } };
    }

    private async sendJSONRequest<T = any>(method: "GET" | "POST" | "PUT" | "DELETE", relativeUrl: string, payload?: any): Promise<{ data: T, status: number }> {
        return new Promise<{ data: T, status: number }>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const url = this.defaults.baseURL + "/" + relativeUrl;

            try {
                xhr.timeout = 10000;
                xhr.onload = () => {
                    if (xhr.status < 400) {
                        resolve({ data: xhr.responseText ? JSON.parse(xhr.responseText) : undefined as T, status: xhr.status });
                    } else {
                        reject(xhr.statusText);
                    }
                };
            }
            catch (ex) {
                console.error("Error during init of request: " + ex);
            }

            xhr.open(method, url);

            // Request headers
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json");
            Object.entries(this.defaults.headers.common).forEach(([key, value]) => xhr.setRequestHeader(key, value as string));

            if (payload) {
                xhr.send(JSON.stringify(payload));
            } else {
                xhr.send();
            }
        });
    }

    async get<T = any>(relativeUrl: string): Promise<{ data: T, status: number }> {
        return this.sendJSONRequest<T>("GET", relativeUrl);
    }

    async post<T = any>(relativeUrl: string, payload?: any): Promise<{ data: T, status: number }> {
        return this.sendJSONRequest<T>("POST", relativeUrl, payload);
    }

    async put<T = any>(relativeUrl: string, payload?: any): Promise<{ data: T, status: number }> {
        return this.sendJSONRequest<T>("PUT", relativeUrl, payload);
    }

    async delete<T = any>(relativeUrl: string, payload?: any): Promise<{ status: number }> {
        return this.sendJSONRequest<T>("DELETE", relativeUrl, payload);
    }
}

export const httpClient = new HttpClient();
