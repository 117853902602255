export default {
  pinkBackground: {
    background: 'linear-gradient(45deg, #e85887 30%, #f3a29a 90%)',
    color: '#fff',
  },
  pinkBackgroundReverse: {
    background: 'linear-gradient(45deg, #f3a29a 30%, #e85887 90%)',
    color: '#fff',
  },
  purpleBackground: {
    background: 'linear-gradient(45deg, #6a7eb8 30%, #56529b 90%)',
    color: '#fff',
  },
  purpleBackgroundReverse: {
    background: 'linear-gradient(45deg, #56529b 30%, #6a7eb8 90%)',
    color: '#fff',
  },
};
