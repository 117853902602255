import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Button, Card, CardActions, CardContent, Divider, IconButton, TextareaAutosize } from "@mui/material";
import styled from '@mui/styled-engine';
import { Field, Form, Formik, FormikHelpers } from "formik";
import "moment/locale/nl";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { User } from "../../Auth/Auth";
import { Family } from "../../Families/Family";
import { selectedFamilySelector } from "../../Families/familySlice";
import { Message } from "./../Chat";
import { addChatMessage, fetchChatMessages } from "./../chatSlice";

const CreateMessageCard = styled(Card)(({ theme }: any) => ({
  borderRadius: "0.5rem",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const AttachmentImage = styled("img")(({ theme }: any) => ({
  maxWidth: "100%",
  maxHeight: theme.spacing(32),
}));

const CreateMessageCardContent = styled(CardContent)(({ theme }: any) => ({
  padding: theme.spacing(0, 0, 2),
}));

const InputField = styled(Field)(({ theme }: any) => ({
  marginLeft: theme.spacing(1),
  fontFamily: "Montserrat",
  fontSize: "1rem",
  fontWeight: 400,
  width: "100%",
  flex: 1,
  minHeight: "2rem",
  border: "none",
  overflow: "auto",
  outline: "none",
  WebkitBoxShadow: "none",
  MozBoxShadow: "none",
  boxShadow: "none",
  resize: "none" /*remove the resize handle on the bottom right*/,
}));

const CreateMessageCardActions = styled(CardActions)(({ theme }: any) => ({
  padding: theme.spacing(1, 1, 0),
}));

const IconButtonContainer = styled(Box)(() => ({
  flexGrow: 1,
}));

const IconButtonWithPadding = styled(IconButton)(({ theme }: any) => ({
  padding: theme.spacing(1),
}));

// const useStyles = makeStyles((theme: Theme) => ({
//   input: {
//     marginLeft: theme.spacing(1),
//     fontFamily: "Montserrat",
//     fontSize: "1rem",
//     fontWeight: 400,
//     width: "100%",
//     flex: 1,
//     minHeight: "2rem",
//     border: "none",
//     overflow: "auto",
//     outline: "none",
//     WebkitBoxShadow: "none",
//     MozBoxShadow: "none",
//     boxShadow: "none",
//     resize: "none" /*remove the resize handle on the bottom right*/,
//   },
//   iconButton: {
//     padding: theme.spacing(1),
//   },
//   iconButtonContainer: {
//     flexGrow: 1,
//   },
//   createMessageCard: {
//     borderRadius: "0.5rem",
//     padding: theme.spacing(1),
//     marginBottom: theme.spacing(2),
//   },
//   createMessageCardContent: {
//     padding: theme.spacing(0, 0, 2),
//   },
//   createMessageCardMedia: {},
//   createMessageCardActions: {
//     padding: theme.spacing(1, 1, 0),
//   },
//   attachment: {
//     maxWidth: "100%",
//     maxHeight: theme.spacing(32),
//   },
// }));

const ChatWriter: FunctionComponent<{
  family: Family;
  user: User;
}> = ({ user }) => {
  const { t } = useTranslation();
  // const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedFamily = useAppSelector(selectedFamilySelector);
  const [attachmentImage, setAttachmentImage] = useState<string>();

  useEffect(() => {
    if (selectedFamily?.community_id) {
      dispatch(fetchChatMessages(selectedFamily.community_id));
    }
  }, [selectedFamily, dispatch]);

  const addAttachment = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/x-png,image/jpg,image/jpeg";
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image.jpg") {
        toast.error(t("invalid file type"));
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent: any) => {
        const content = readerEvent.target.result;

        setAttachmentImage(content);
      };
    };

    input.click();
  };

  const handleFormSubmit = async (values: { message: string }, { setSubmitting, setErrors, setStatus, resetForm }: FormikHelpers<any>) => {
    if (!selectedFamily?.community_id) return;

    const message: Message = { message: values.message, user: user, favorites: [], attachment_id: 0 };
    if (attachmentImage !== undefined) {
      message.media = attachmentImage;
    }

    if (message.message !== "" || message.media !== undefined) {
      dispatch(addChatMessage(selectedFamily.community_id, message));

      setAttachmentImage(undefined);

      resetForm({});
      setStatus({ success: true });
    }
  };

  return (
    <CreateMessageCard>
      <Formik initialValues={{ message: "" }} onSubmit={handleFormSubmit}>
        {({ values, handleChange }) => (
          <Form>
            {attachmentImage && <AttachmentImage src={attachmentImage} alt="attachment" />}
            <CreateMessageCardContent>
              <InputField component={TextareaAutosize} value={values.message} name="message" onChange={handleChange("message")} placeholder={t("type message")} />
            </CreateMessageCardContent>
            <Divider />
            <CreateMessageCardActions>
              <IconButtonContainer>
                <IconButtonWithPadding
                  color="primary"
                  aria-label="send"
                  onClick={addAttachment}
                  edge="start"
                  size="large">
                  <AttachFileIcon />
                </IconButtonWithPadding>
              </IconButtonContainer>
              <Button variant="contained" size="small" color="primary" type="submit" disabled={!(values.message !== undefined && values.message.length > 0)}>
                {t("post")}
              </Button>
            </CreateMessageCardActions>
          </Form>
        )}
      </Formik>
    </CreateMessageCard>
  );
};

export default ChatWriter;
