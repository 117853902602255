import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { toast } from 'react-toastify';
import { AppThunk, RootState } from "../../store/store";
import { httpClient } from "../../utils/httpClient";
import { Member } from "../Members/Member";
import { setOpenMember } from "../Members/membersSlice";
interface UserState {
  isLoading: boolean;
  errorMessage: string | undefined;
  users: Member[];
  selectedUser: Member | undefined
}

const initialState: UserState = {
  isLoading: false,
  errorMessage: undefined,
  users: [],
  selectedUser: undefined
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<UserState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<UserState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setUsers(state, action: PayloadAction<UserState["users"]>) {
      state.users = action.payload;
    },
    setSelectedUser(state, action: PayloadAction<UserState["selectedUser"]>) {
      state.selectedUser = action.payload;
    },
  },
});

export const usersReducer = userSlice.reducer;

const { setLoading, setErrorMessage, setUsers, setSelectedUser } = userSlice.actions;

export const setSelectedUserFromList = (user: Member): AppThunk => async (dispatch) => {
  dispatch(setSelectedUser(user));
}

export const fetchUsers = (): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`user/administrators`)
    .then((response) => {

      const users = response.data;

      batch(() => {
        dispatch(setUsers(users));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setErrorMessage("There was an error while trying to fetch the users list"));
      });
      toast.error(error.response?.data.message || error.message);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const inviteUser = (userId: number, role: string, user: Member): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.get(`user/exist?email=${encodeURIComponent(user.email ?? "")}`)
    .then((existsResponse) => {
      if (existsResponse.status === 200 && existsResponse.data) {
        toast.error("emailExists");
        return;
      }

      httpClient.post(`user/invite/${role}`, user)
        .then(() => {
          batch(() => {
            dispatch(fetchUsers());
            dispatch(setOpenMember(undefined));
          });
        })
        .catch(() => {
          toast.error("userSaveFailed");
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setErrorMessage("There was an error while trying to fetch the members list"));
      });
      toast.error(error.response?.data.message || error.message);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const updateUser = (userId: string, user: Member): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(undefined));
  });

  httpClient.get(`user/exist?email=${encodeURIComponent(user.email ?? "")}`)
    .then((existsResponse) => {
      if (existsResponse.status === 200 && existsResponse.data) {
        toast.error("emailExists");
        return;
      }

      httpClient.put(`user/${userId}`, user)
        .then(() => {
          batch(() => {
            dispatch(fetchUsers());
            dispatch(setOpenMember(undefined));
            dispatch(setLoading(true));
          })
        })
        .catch(() => {
          dispatch(setErrorMessage("There was an error while trying to save the user"));
          toast.error("userSaveFailed");
        })
        .finally(() => {
          dispatch(setLoading(false));
        });

    })
    .catch((error) => {
      batch(() => {
        dispatch(setErrorMessage("There was an error while trying to fetch the users list"));
      });
      toast.error(error.response?.data.message || error.message);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const deleteUser = (userId: number): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  httpClient.delete(`user/${userId}`)
    .then(() => {
      batch(() => {
        dispatch(fetchUsers());
        dispatch(setOpenMember(undefined));
        dispatch(setLoading(true));
      });
    })
    .catch((error) => {
      batch(() => {
        dispatch(setErrorMessage("userDeleteFailed"));
      });
      toast.error(error.response?.data.message || error.message);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
}


export const usersSelector = (state: RootState) => state.users.users;
export const selectedUserSelector = (state: RootState) => state.users.selectedUser;
export const familiesIsLoadingSelector = (state: RootState) => state.users.isLoading;
export const familiesErrorMessageSelector = (state: RootState) => state.users.errorMessage;
